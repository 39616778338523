@import 'insight/Base.scss';

.PlansPage-wrapper {
  @include container;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .PlansPage-plans-container,
  .PlansPage-details-container {
    @include container;
    max-width: 1024px;
    display: flex;

    height: 100%;
    width: 100%;

    justify-content: center;
    align-items: center;
    overflow: auto;

    flex-wrap: wrap;

    &.show-details {
      align-items: flex-start;
    }
  }
  .PlansPage-show-details-button {
    padding: 1em;
    font-size: 1.2em;
    opacity: 0.4;
  }

  .PlansPage-plans-container {
    &.no-details {
      height: 400px;
    }
  }

  .PlansPage-details-container {
    padding: 20px;
    background:red
  }
}
