@import 'libs/Base';

.Form-wrapper {
  @include container;

  flex-direction: column;
  align-items: flex-start;

  > button:not(:first-child) {
    // TODO: sync with material theme
    margin-top: 16px;
    // margin-bottom: 8px;
  }
}
