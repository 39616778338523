@import 'insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $height: constant('header-height');
  $menu-width: constant('menu-width');
  $padding: constant('base-padding');
  $bold: constant('font-weight-3');
  $small: constant('font-size-small');
  $normal: constant('font-size-normal');

  $background-1: theme('background-1');
  $background-2: theme('background-2');
  $border-1: theme('border-1');
  $border-2: theme('border-2');
  $color: theme('font-1');
  $hover: theme('emphasis-1');
  $focus: theme('emphasis-1');

  .Header-wrapper {
    @include container;

    justify-content: space-between;
    align-items: center;

    width: 100vw;
    height: $height;

    z-index: 1001;

    user-select: none;
    filter: drop-shadow(0 -5px 10px $border-2);
    background-color: $background-2;

    .Header-logoDiv {
      @include container;

      align-items: center;

      width: $menu-width;
      height: 100%;

      flex-grow: 0;
      flex-shrink: 0;
      
      cursor: pointer;

      > svg {
        width: calc(100% - 2 * #{$padding});

        margin: 0 $padding;
        g {
          &.color {
            > path {
              fill: $color;
            }
          }
          &.emphasis {
            > path {
              fill: $hover;
            }
          }
        }
      }
    }
    .Header-contextualMenuDiv {
      @include container;

      margin-left: calc(#{$padding} * 3);

      > div {
        border-left: 1px solid rgba($color: $border-1, $alpha: 0.2);
        &:last-child {
          border-right: 1px solid rgba($color: $border-1, $alpha: 0.2);
        }
      }
    }
    .Header-controlBar {
      @include container;

      justify-content: flex-end;

      width: 8em;

      z-index: 1;

      color: $color;

      // .control-button {
      //   color: inherit;
      //   font-size: $small;
      //   font-weight: $bold;
      //   text-decoration: none;
      //   text-transform: uppercase;
      //   padding: $padding;
      // }
      .Header-login-link {
        display: flex;
        align-items: center;
        
        margin-right: 20px;
        
        color: $color;
        letter-spacing: 2px;
        font-size: 0.9125em;
        text-transform: uppercase;
        text-decoration: none;
      }
      .Icon-wrapper {
        width: 2em;
        height: 2em;

        padding: $padding;
        cursor: pointer;
        border-radius: 3px;
        &:hover {
          color: $hover;
        }
        &:focus {
          background-color: $focus;
        }
      }
      .Header-userDropdown {
        @include container;

        flex-direction: column;
        justify-content: flex-start;

        width: 8em;

        flex-grow: 0;

        z-index: 1;
        overflow-y: hidden;

        transition: all 0.3s;

        &:hover,
        &:focus-within {
          height: calc(#{$height} + (2.5em * 2) + 1px);
          // height: 300px;
        }
        .Header-accountButton {
          @include container;

          height: $height;

          top: 0;
          flex-grow: 0;
          flex-shrink: 0;

          text-decoration: none;
          color: $color;

          > span {
            width: 100%;

            line-height: $height;
            padding: 0 $padding;
            text-align: end;
          }

          &:focus {
            outline: 2px solid $hover;
            outline-offset: -5px;
          }
          &:hover {
            background-color: $hover;
            color: $color;
          }
        }
        > div {
          @include container;

          flex-direction: column;

          border: 2px solid $background-2;
          border-top: 0;
          background-color: $background-1;

          .Header-userDropdown-item {
            @include container;

            justify-content: flex-end;
            align-items: center;

            height: 2.5em;
            width: 100%;

            flex-shrink: 0;

            padding: $padding;

            font-size: $normal;
            color: $color;
            text-decoration: none;
            cursor: pointer;
            border-radius: 0;
            border: none;
            background: none;

            &:focus {
              outline: 2px solid $hover;
              outline-offset: -3px;
            }
            &:hover {
              background-color: $hover;
              color: $color;
            }
          }
        }
      }
    }
  }
}
