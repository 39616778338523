@import 'app/insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $padding: constant('base-padding');

  .ChartWrapper-wrapper {
    @include container;
    flex-direction: column;

    width: 100%;
    height: 100%;
    min-width: 0;
    min-height: 0;

    flex-shrink: 1;

    .ChartWrapper-horizontalAligner {
      @include container;
      flex-direction: row;

      // width: 100%;
      // height: 100%;
      min-width: 0;
      min-height: 0;

      flex-shrink: 1;
    }
    .ChartWrapper-footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      width: 100%;
      height: 1.25em;

      flex-grow: 0;
      flex-shrink: 0;

      font-size: 0.9em;

      line-height: 1.5em;

      overflow: hidden;

      svg {
        width: 20px;
        height: 20px;
      }
      > button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        width: 20px;
        height: 20px;

        margin-left: 5px;

        animation: graphFooterZoomButtonsFlyIn 0.3s forwards;

        @keyframes graphFooterZoomButtonsFlyIn {
          0% {
            margin-right: -40px;
            opacity: 0;
          }
          100% {
            margin-right: 0px;
            opacity: 1;
          }
        }

        border-radius: 0;
        border: none;
        background: none;
        color: currentColor;
        font-size: inherit;
      }
    }
  }

  .GraphGrid-wrapper {
    position: absolute;
    display: flex;

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    overflow: hidden;

    .GraphGrid-grid {
      width: 100%;
      height: 100%;
      > line {
        stroke: rgb(75, 75, 75);
        stroke-width: 0.5;
        vector-effect: non-scaling-stroke;
      }
    }
  }

  .ChartWrapper-graphDiv {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .GraphOverlay-wrapper {
    position: absolute;
    display: flex;

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    overflow: hidden;

    .GraphOverlay-overlay {
      width: 100%;
      height: 100%;

      cursor: crosshair;

      .GraphOverlay-shadow {
        fill: rgba(0, 0, 0, 0.3);
      }
      .GraphOverlay-borderBox {
        fill: none;
        stroke: dodgerblue;
        stroke-width: 1;
        vector-effect: non-scaling-stroke;
      }
    }
  }
}
