.Spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  svg {
    width: 100%;
    height: 100%;

    > rect {
      animation: spinnerOpacityAnimation 0.15s forwards;

      @keyframes spinnerOpacityAnimation {
        0% {
          opacity: 0;
        }
        99% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }
}
