.PopUpMenu-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;

  // width: 100%;
  // height: 100%;
  flex-grow: 0;
  flex-shrink: 1;

  .PopUpMenu-button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    // width: 100%;
    height: 2em;
    flex-grow: 1;
    flex-shrink: 1;

    padding: 10px;
    transition: all 0.4s;
    // background-color: #6e727c; mitar
    background-color: #2e313a;

    &:hover {
      background-color: dodgerblue;
    }

    .PopUpMenu-arrow {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: 1em;
      height: 1em;
      flex-grow: 0;
      flex-shrink: 0;

      background-color: #171a24;
    }
  }

  .PopUpMenu-submenu {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: stretch;

    // width: 100%;
    // height: 1.5em;
    flex-grow: 0;
    flex-shrink: 1;

    z-index: -1;
    opacity: 0;
    transition: opacity 0.4s;

    // background-color: #6e727c; mitar
    background-color: #2e313a;

    .PopUpMenu-item {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      // width: 100%;
      height: 2em;
      flex-grow: 1;
      flex-shrink: 1;

      padding: 10px;

      font-size: inherit;
      color: inherit;
      border: none;
      // background-color: #6e727c; mitar
      background-color: #2e313a;

      &:hover {
        background-color: dodgerblue;
      }

      .PopUpMenu-checked {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 1em;
        height: 1em;
        flex-grow: 0;
        flex-shrink: 0;

        background-color: #171a24;
      }
    }
  }
}
