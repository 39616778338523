@import 'insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $padding: constant('base-padding');
  $large-padding: constant('large-padding');
  $ultra-thin: constant('font-weight-0');
  $thin: constant('font-weight-1');
  $bold: constant('font-weight-3');
  $font-small: constant('font-size-small');
  $font-normal: constant('font-size-normal');
  $font-large: constant('font-size-large');
  $font-giant: constant('font-size-giant');
  $font-huge: constant('font-size-huge');

  $color: theme('font-1');
  $color-2: theme('font-2');
  $border-1: theme('border-1');
  $section-separator: theme('background-2');
  $background-2: theme('background-2');
  $hover: theme('emphasis-1');
  $focus: theme('emphasis-1');

  .PlanCard-container {
    display: flex;

    min-width: 280px;
    width: 300px;
    height: fit-content;
    padding: 70px 20px;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: $background-2, $alpha: 0.7);
    max-height: unset;

    .PlanCard-name {
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: rgba($color: $color, $alpha: 0.7);
    }
    .PlanCard-price {
      margin: 20px 0 0;
      font-weight: 200;
      font-size: 50px;
    }
    .PlanCard-billing {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #888;
    }
    .PlanCard-btn-purchase {
      margin-top: 20px;
      width: 10em;
    }

    .PlanCard-description {
      position: relative;
      margin-top: 20px;
      padding: 10px;
      font-size: 0.875em;
      letter-spacing: 1px;
      font-weight: 300;
      text-align: center;

      height: 10em;
      overflow-y: auto;
      // white-space: nowrap;
      // text-overflow: ellipsis;

      &::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        height: 1px;
        width: 200px;
        background-color: #ccc;
        transform: translateX(-50%);
      }
    }

    .PlanCard-features,
    .PlanCard-licences,
    .PlanCard-licencesType,
    .PlanCard-processes,
    .PlanCard-sizeLimit {
      opacity: 1;
      transition: opacity 1s;
      width: 80%;
      display: flex;
      font-size: 1.125em;
      font-variant: all-small-caps;
      letter-spacing: 1px;
    }
    .PlanCard-features {
      font-size: 1.25em;
      font-weight: 300;
      margin-top: 20px;
    }
    .PlanCard-licences,
    .PlanCard-licencesType,
    .PlanCard-processes,
    .PlanCard-sizeLimit {
      margin-left: 10px;
    }
  }
}
