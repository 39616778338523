.ScoringWizard-step-one {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .ScoringWizard-no-data-msg {
    margin-top: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.5em;
  }

  .ScoringWizard-alternative-data-msg {
    margin-top: 20px;
  }
  .ScoringWizard-alternative-actions {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    > .MuiButtonBase-root {
      min-width: 100px;
      margin: 0 20px;
    }
  }
  .ScoringWizard-action-link {
    color: #3fa9f5;
    cursor: pointer;
    text-decoration: none;
    &:focus {
      text-decoration: underline;
      transform: scale(1.1, 1.1);
    }
  }

  .MuiInputBase-root {
    width: 300px;
    max-width: 100%;
  }
}
