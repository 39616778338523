@import 'insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $app-header-height: constant('header-height');
  $menu-width: constant('menu-width');
  $minimized-menu-width: constant('minimized-menu-width');
  $padding: constant('base-padding');
  $padding-large: constant('large-padding');
  $font-normal: constant('font-size-normal');
  $font-large: constant('font-size-large');
  $very-thin: constant('font-weight-0');
  $thin: constant('font-weight-1');
  $header-height: 3.5em;
  // $footer-height: 2.5em;
  $footer-height: 0;
  // $controls-height: calc(3em + #{$padding});
  $controls-height: 5em;
  $graphs-width: 60em;
  $table-row-height: 2.5em;

  $color: theme('font-1');
  $header-footer-background: theme('background-2');
  $background-dark: theme('background-1');
  $border: theme('border-1');
  $font-disabled: theme('font-2');
  $hover: theme('emphasis-1');

  .TrainingInspector-wrapper {
    @include container;

    flex-direction: column;

    .TrainingInspector-header {
      @include container;

      justify-content: flex-start;
      align-items: center;

      height: $header-height;

      flex-grow: 0;

      padding: $padding;

      background-color: $header-footer-background;

      .TrainingInspector-title {
        font-size: $font-large;
        font-weight: $very-thin;
        text-transform: uppercase;
      }
      .TrainingInspector-trainingStatus {
        margin-left: $padding;
        font-weight: $thin;
        text-transform: lowercase;
        user-select: none;
      }
      .TrainingInspector-closeButton {
        @include container;

        justify-content: center;
        align-items: center;

        width: calc(3.5em - 10px);
        height: calc(3.5em - 10px);
        min-width: 0;

        flex-grow: 0;
        flex-shrink: 0;

        font-size: 1em;
        border-radius: 50%;
        border: none;
        background: none;
        margin-left: auto;
        color: currentColor;
        cursor: pointer;

        transition: all 0.3s;
        &:hover {
          background-color: $hover;
        }
      }
    }

    .TrainingInspector-content {
      @include container;

      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      height: calc(100% - #{$header-height + $footer-height});

      padding: $padding;

      user-select: none;
      overflow: hidden;

      // .TrainingInspector-userControlsDiv {
      //   @include container;

      //   justify-content: space-between;
      //   align-items: center;

      //   height: $controls-height;

      //   > div {
      //     // &:first-child {
      //     //   width: calc(60% - 15px);
      //     // }
      //     &:last-child {
      //       justify-content: flex-end;
      //       // width: calc(40% - 15px);
      //     }
      //   }
      // }
      .TrainingInspector-visualsDiv {
        @include container;

        justify-content: space-between;
        align-items: flex-start;

        // height: calc(100% - #{$controls-height});

        .TrainingInspector-graphsDiv {
          @include container;

          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;

          width: $graphs-width;
          height: calc(100% - #{$padding} * 2);

          flex-grow: 0;
          flex-shrink: 0;

          margin: $padding;

          overflow: hidden;
          overflow-y: auto;

          > div {
            @include container;

            flex-direction: column;
            justify-content: flex-end;

            height: calc(50% - #{$padding} / 2);

            flex-grow: 0;
            flex-shrink: 0;

            margin-bottom: $padding;

            &:not(:first-child) {
              border-top: 1px solid rgba($color: $color, $alpha: 0.5);
            }

            > span {
              @include container;

              width: fit-content;
              height: 2em;

              font-size: $font-large;
              line-height: 2em;
              font-weight: $thin;
              text-transform: uppercase;

              .TrainingInspector-infoIcon {
                @include container;

                justify-content: center;
                align-items: center;

                width: unset;
                height: unset;

                flex-grow: 0;

                margin: auto $padding;

                > svg {
                  font-size: $font-normal;
                }
              }
            }

            > div {
              height: calc(100% - 2 * #{$font-large});

              flex-grow: 0;
              flex-shrink: 0;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .TrainingInspector-rightSideDiv {
          @include container;

          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          width: calc(100% - #{$graphs-width} - #{$padding} * 4);
          height: calc(100% - #{$padding} * 2);

          flex-grow: 0;

          margin: $padding;

          .TrainingInspector-userControlsDiv {
            @include container;

            height: unset;
          }

          .TrainingInspector-tableDiv {
            @include container;

            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            height: calc(100% - #{$controls-height});

            flex-shrink: 1;

            .Table-Column-rowCell {
              height: $table-row-height;
              background-color: $background-dark;
              border-bottom: 1px solid rgba($color: $border, $alpha: 0.2);
            }

            > .Spinner-wrapper {
              width: 10em;
              height: 10em;
              margin: auto;
            }
          }
          // .ConfusionMatrixChart-wrapper {
          //   height: calc(50% - 15px);
          // }
        }
      }
    }

    .TrainingInspector-footer {
      @include container;

      justify-content: flex-start;
      align-items: center;

      height: $footer-height;

      flex-grow: 0;

      padding: $padding;

      border-top: 1px solid $border;
      background-color: $header-footer-background;
    }
  }

  .MuiDialog-root {
    &.TrainingInspector-dialog {
      position: absolute !important;

      width: calc(100% - #{$menu-width} - 2 * #{$padding-large});
      height: calc(100% - #{$header-height} - 2 * #{$padding-large});

      top: calc(#{$header-height + $padding-large}) !important;
      left: calc(#{$menu-width + $padding-large}) !important;

      font-family: 'Roboto', sans-serif !important;

      backdrop-filter: blur(2px);
      transition: all 0.3s;

      &.wide {
        width: calc(100% - #{$minimized-menu-width} - 2 * #{$padding-large});

        left: calc(#{$minimized-menu-width + $padding-large}) !important;
      }

      > .MuiDialog-container {
        > .MuiPaper-root {
          border-radius: 0 !important;
          background: none !important;
          background-color: rgba($color: $background-dark, $alpha: 0.9) !important;
          border: 1px solid $header-footer-background;
        }
      }

      // > .MuiBackdrop-root {
      //   //   opacity: 0 !important;
      // }
    }
  }
}
