@import 'app/insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $padding: constant('base-padding');
  $bold: constant('font-weight-3');
  $controls-bar-height: 10em;

  $legend-entry-background: theme('background-1');
  $border-color: theme('border-1');
  $font-color: theme('font-1');
  $disabled: theme('font-2');
  $black: theme('black-1');

  .LineChart-wrapper {
    @include container;

    color: $font-color;
    user-select: none;

    .LineChart-UserControlWrapper-wrapper {
      @include container;

      flex-direction: column;
      justify-content: flex-start;

      .LineChart-UserControlsBar-wrapper {
        @include container;

        justify-content: flex-start;
        align-items: flex-start;

        height: $controls-bar-height;

        z-index: 1;

        > div {
          &:not(:first-child) {
            margin-left: $padding;
          }
        }
      }
    }
  }
  .LineChart-LineGraph-wrapper {
    @include container;

    min-height: 0;

    user-select: none;

    .LineChart-LineGraph-loading {
      @include container;
      position: absolute;

      top: 0;
      left: 0;

      padding: $padding;

      overflow: hidden;

      background: linear-gradient(
        76deg,
        rgba(255, 255, 255, 0) 34%,
        rgba(30, 144, 255, 0.1) 52%,
        rgba(30, 144, 255, 0.1) 55%,
        rgba(255, 255, 255, 0) 66%
      );
      border: none;
      background-size: 400% 100%;
      filter: none;

      animation: chartDataLoading 3.5s ease infinite;

      @keyframes chartDataLoading {
        0% {
          background-position-x: 100%;
        }
        100% {
          background-position-x: 0%;
        }
      }

      > .Spinner-wrapper {
        width: 1.2em;
        height: 1.2em;
        margin-top: 0.1em;
        margin-left: calc(#{$padding} / 2);
        margin-bottom: -0.5em;
      }

      > span {
        text-align: right;
        line-height: 1.5em;
        margin-left: $padding;
        margin-bottom: -0.5em;
        max-width: 8em;
      }
    }

    .LineChart-LineLegend-wrapper {
      @include container;

      flex-direction: column;
      justify-content: flex-start;

      flex-grow: 0;
      flex-shrink: 0;
      padding: calc(#{$padding} / 2);

      overflow: hidden;
      overflow-y: auto;

      .LineChart-LineLegend-entry {
        width: 100%;
        height: 2em;

        flex-shrink: 0;
        padding: calc(#{$padding} / 2);

        user-select: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        background-color: $legend-entry-background;

        &:not(:first-child) {
          margin-top: calc(#{$padding} / 2);
        }
      }
    }

    .LineChart-LineGraph-graphSVG {
      @include container;

      // border-color: $border-color !important;

      > path {
        transition: fill-opacity 0.3s;
        transition-timing-function: linear;
        fill-opacity: inherit;
        fill: currentColor;
        stroke: currentColor;
        stroke-width: 0.5;
        vector-effect: non-scaling-stroke;
      }
    }

    .LineChart-LineGraph-noDataDiv {
      @include container;

      position: absolute;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: 100%;
      height: 100%;

      top: 0;
      left: 0;

      background-color: rgba($color: $black, $alpha: 0.25);

      > div {
        @include container;

        justify-content: center;
        align-items: center;

        width: 100%;
        height: unset;

        flex-grow: 0;

        &:first-child {
          height: 1em;

          font-size: 2em;
          font-weight: $bold;

          text-transform: uppercase;
        }
        &:last-child {
          height: 2em;

          font-size: 1em;
          font-weight: 300;

          font-variant: small-caps;
          color: $disabled;
        }
      }
    }
  }
}
