@import 'libs/Base.scss';

.ModelCard-wrapper {
  @include container;
  flex-direction: column;

  width: 225px;
  height: 300px;

  border-top: none;
  border-left: none;
  overflow: hidden;
  border: 1px solid black;

  background: linear-gradient(
    0deg,
    rgba(35, 38, 47, 1) 50%,
    rgba(65, 69, 84, 1) 100%
  );
  filter: drop-shadow(4px 2px 6px #0d0e13);

  .ModelCard-header {
    @include container;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 35px;

    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 10px;

    text-transform: uppercase;
  }

  .ModelCard-content {
    @include container;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    height: 183px;

    overflow: hidden;

    .ModelCard-main {
      @include container;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: 153px;
      height: 153px;

      flex-grow: 0;
      flex-shrink: 0;
      margin: 5px;

      transition: opacity 0.6s;

      > svg {
        position: absolute;
        top: 0;
        left: 0;
      }
      > span {
        text-transform: uppercase;
      }
      &.expanded {
        opacity: 0;
      }
    }

    .ModelCard-details {
      width: 100%;
      height: 183px;

      flex-grow: 0;
      flex-shrink: 0;

      padding: 0 10px;
      margin-bottom: -2em;

      z-index: 1;
      transition: all 0.4s;
      user-select: none;

      .ModelCard-showMore {
        @include container;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 20px;

        flex-grow: 0;
        flex-shrink: 0;
        padding: 0 10px;

        font-size: 0.7em;

        opacity: 0.5;
        text-transform: uppercase;
        text-decoration: underline;
        user-select: none;
        cursor: pointer;

        > svg {
          transition: transform 0.3s;
        }
      }

      &.expanded {
        margin-top: -163px;
        .ModelCard-showMore {
          > svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .ModelCard-footer {
    @include container;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: calc(100% - 10px);
    height: 70px;

    flex-grow: 0;
    flex-shrink: 0;
    margin: 5px;
    font-size: 0.8em;
    transition: height 0.3s;

    overflow: hidden;
    border: 1px solid #171a24;

    .ModelCard-actionButton {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      width: 50px;
      height: 50px;
      background: none;

      font: inherit;
      cursor: pointer;
      user-select: none;
      color: inherit;
      border: none;
      border-radius: unset;

      &:not(:first-child) {
        margin-left: 5px;
      }

      .Icon-wrapper {
        width: 75%;
        height: 75%;
      }
    }
  }
}
