@import 'insight/Base.scss';

$header-height: constant('header-height');
$menu-width: constant('menu-width');
$minimized-menu-width: constant('minimized-menu-width');

.ScoringWizard-wrapper {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  font-family: 'Roboto', sans-serif;

  background-color: #171a24;

  .ScoringWizard-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    height: 3.5em;

    padding: 10px;

    user-select: none;

    border-bottom: 1px solid gray;
    background-color: #23262f;

    .ScoringWizard-title {
      font-size: 1.4em;
      font-weight: 300;
      text-transform: uppercase;
    }

    .ScoringWizard-closeButton {
      display: flex;
      justify-content: center;
      align-items: center;

      min-width: calc(3.5em - 10px);
      width: calc(3.5em - 10px);
      height: calc(3.5em - 10px);

      font-size: 1em;
      border-radius: 50%;
      border: none;
      background: none;
      margin-left: auto;
      color: currentColor;
      cursor: pointer;

      transition: all 0.3s;
      // &:hover {
      //   background-color: dodgerblue;
      // }
    }
  }

  .ScoringWizard-content {
    position: relative;
    display: flex;
    flex-direction: column;

    width: 100%;
    height: calc(100% - 6.5em);

    flex-grow: 1;
    user-select: none;
    overflow: hidden;

    .ScoringWizard-overlayWrapper {
      @include container;
      position: absolute;
      width: 100%;
      height: 100%;
      display: none;
      opacity: 0;
      transition: all 1s;
      &[data-active='true'] {
        display: flex;
        opacity: 1;
      }
    }
    // .ScoringWizard-progressWrapper {
    //   display: flex;
    //   align-items: flex-end;
    //   justify-content: flex-start;
    //   padding: 0px 120px;
    //   flex-grow: 0;
    //   width: 100%;
    //   height: 3em;

    //   .ScoringWizard-progressBar {
    //     width: 100%;
    //     height: 5px;
    //     border-radius: 20px;
    //     background: #3fa9f5;
    //   }
    // }

    .ScoringWizard-stepsWrapper {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding: 0 10px;

      width: 100%;
      height: 100%;
      flex-grow: 1;

      .ScoringWizard-step {
        height: 100%;
        width: calc(33% - 80px);

        .ScoringWizard-progressWrapper {
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          flex-grow: 0;
          width: 100%;
          height: 3em;

          .ScoringWizard-progressBar {
            margin-left: 1%;
            width: 0px;
            border: solid 1px #171a24;
            height: 5px;
            background: #3fa9f5;
            transition: all 0.8s ease-in, background-color 1s 0.4s;
            &[data-completed='true'] {
              width: 98%;
              // background: rgba(0, 255, 0, 1);
              background: #3fa9f5;
              border-color: black;
            }
          }
        }

        .ScoringWizard-stepContent {
          display: flex;
          position: relative;

          width: calc(100% - 40px);
          height: calc(100% - 100px);
          margin: 20px;
          padding: 20px;
          padding-top: 150px;

          border: solid 1px black;
          background-color: #23262f;
          box-shadow: -20px 0px 20px 0px rgba(0, 0, 0, 0.3),
            0px 20px 20px 0px rgba(0, 0, 0, 0.3);

          transition: all 0.5s;

          opacity: 0.5;
          pointer-events: none;

          h2 {
            color: #3fa9f5;
            font-size: 2em;
            font-weight: 200;
            margin-bottom: 20px;
          }
        }
        &[data-active='true'] {
          .ScoringWizard-stepContent {
            opacity: 1;
            pointer-events: unset;

            width: calc(100% - 20px);
            padding-left: 30px;
            padding-right: 30px;
            height: calc(100% - 80px);
            margin: 10px;
            background-color: #2e313a;
          }
        }
      }
    }
  }

  .ScoringWizard-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 3em;

    padding: 10px;

    user-select: none;

    border-top: 1px solid gray;
    background-color: #2e313a;

    button {
      margin-right: 10px;
    }
  }
}

.MuiDialog-root {
  &.ScoringWizard-dialog {
    position: absolute !important;

    // width: calc(100% - 16em - 40px);
    // height: calc(100% - 2.5em - 40px);

    // width: calc(100% - $menu-width);
    // height: calc(100% - $header-height);

    // top: calc(2.5em + 20px) !important;
    // left: calc(16em + 20px) !important;

    // top: $header-height !important;
    // left: $menu-width !important;
    font-family: 'Roboto', sans-serif !important;

    // > .MuiDialog-container {
    //   > .MuiPaper-root {
    //     // border-radius: 3px !important;
    //   }
    // }

    // > .MuiBackdrop-root {
    //   // opacity: 0 !important;
    // }
  }
}

.Cpu-modal {
  width: 200px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}
