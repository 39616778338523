.TrainingWizard-step-one {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .TrainingWizard-no-data-msg {
    margin-top: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.5em;
  }

  .TrainingWizard-alternative-data-msg {
    margin-top: 20px;
    text-align: center;
  }

  .TrainingWizard-action-link {
    color: #3fa9f5;
    cursor: pointer;
    text-decoration: none;
    &:focus {
      text-decoration: underline;
      transform: scale(1.1, 1.1);
    }
  }

  .MuiInputBase-root {
    width: 300px;
    max-width: 100%;
  }
}
