@keyframes axisSnapAnimation {
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.XAxis-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 5em;
  min-width: 0;
  min-height: 0;
  flex-grow: 0;
  flex-shrink: 0;

  user-select: none;

  > svg {
    width: 100%;
    height: 100%;
    animation: axisSnapAnimation 0.2s forwards;
  }
}
.YAxis-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 5em;
  height: 100%;
  min-width: 0;
  min-height: 0;
  flex-grow: 0;
  flex-shrink: 0;

  user-select: none;
  animation: axisSnapAnimation 0.2s forwards;
}
.CategoryXAxis-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 5em;
  min-width: 0;
  min-height: 0;
  flex-grow: 0;
  flex-shrink: 0;
  user-select: none;

  > svg {
    width: 100%;
    height: 100%;
    animation: axisSnapAnimation 0.2s forwards;
  }
}
.CategoryYAxis-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 5em;
  height: 100%;
  min-width: 0;
  min-height: 0;
  flex-grow: 0;
  flex-shrink: 0;

  user-select: none;
  animation: axisSnapAnimation 0.2s forwards;
}
.Axis-label {
  fill: currentColor;
  dominant-baseline: middle;
  text-anchor: middle;
}

.Axis-line {
  stroke: currentColor;
  stroke-width: 1;
  vector-effect: non-scaling-stroke;
}

.Axis-subLine {
  stroke: currentColor;
  stroke-width: 0.6;
  vector-effect: non-scaling-stroke;
}

.Axis-text {
  fill: currentColor;
  dominant-baseline: middle;
}
