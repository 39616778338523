@import 'app/insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $padding: constant('base-padding');
  $thin: constant('font-weight-0');

  $background-color: theme('background-2');
  $shadow-color: theme('border-1');
  $emphasis: theme('emphasis-1');
  $failed: theme('reject-red');
  $completed: theme('accept-green');
  $border-color: theme('border-1');

  .UploadColumnCard-wrapper {
    @include container;
    flex-direction: row;

    height: 4em;

    flex-grow: 0;

    // border: 1px solid black;
    border-top: none;
    border-left: none;
    // overflow: hidden;

    // background-color: $background-color;

    border-radius: calc(#{$padding} / 2);
    border: 1px solid rgba($color: $border-color, $alpha: 0.15);
    // outline: 1px solid rgba($color: $border-color, $alpha: 0.15);
    // outline-offset: -1px;

    filter: drop-shadow(0px 3px 3px rgba($color: $border-color, $alpha: 0.15));

    background: linear-gradient(
        90deg,
        rgba($color: $emphasis, $alpha: 0.5) 35%,
        transparent 35%
      ),
      linear-gradient(
        90deg,
        rgba($color: $emphasis, $alpha: 0.5) 35%,
        transparent 35%
      ),
      linear-gradient(
        0deg,
        rgba($color: $emphasis, $alpha: 0.5) 35%,
        transparent 35%
      ),
      linear-gradient(
        0deg,
        rgba($color: $emphasis, $alpha: 0.5) 35%,
        transparent 35%
      );
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 20px 1px, 20px 1px, 1px 20px, 1px 20px;
    background-position: 0px -1px, 100px calc(100% + 1px), -1px 100px,
      calc(100% + 1px) 0px;
    animation: border-dance 2s infinite linear;

    @keyframes border-dance {
      0% {
        background-position: 0px -1px, 100px calc(100% + 1px), -1px 100px,
          calc(100% + 1px) 0px;
      }
      100% {
        background-position: 100px -1px, 0px calc(100% + 1px), -1px 0px,
          calc(100% + 1px) 100px;
      }
    }

    &.failed {
      animation: UploadColumnCard-colorFailed 4s linear 0s infinite;
      -webkit-animation: UploadColumnCard-colorFailed 4s linear 0s infinite alternate;

      border-radius: calc(#{$padding} / 2);
      border: 1px solid rgba($color: $failed, $alpha: 0.5);
      // outline: 1px solid rgba($color: $failed, $alpha: 0.5);
      // outline-offset: -1px;

      filter: drop-shadow(0px 3px 3px rgba($color: $failed, $alpha: 0.5));

      background: none;

      // @keyframes UploadColumnCard-colorFailed {
      //   30% {
      //     background-color: #23262f;
      //   }
      //   50% {
      //     background-color: #4b121d;
      //   }
      //   70% {
      //     background-color: #23262f;
      //   }
      // }

      // @-webkit-keyframes UploadColumnCard-colorFailed {
      //   30% {
      //     background-color: #23262f;
      //   }
      //   50% {
      //     background-color: #4b121d;
      //   }
      //   70% {
      //     background-color: #23262f;
      //   }
      // }
    }

    &.completed {
      animation: UploadColumnCard-colorCompleted 4s linear 0s infinite;
      -webkit-animation: UploadColumnCard-colorCompleted 4s linear 0s infinite
        alternate;

      border-radius: calc(#{$padding} / 2);
      border: 1px solid rgba($color: $completed, $alpha: 0);
      // outline: 1px solid rgba($color: $completed, $alpha: 0.5);
      // outline-offset: -1px;

      filter: drop-shadow(0px 3px 3px rgba($color: $completed, $alpha: 0.5));

      background: rgba($color: $completed, $alpha: 0.15);

      // @keyframes UploadColumnCard-colorCompleted {
      //   30% {
      //     background-color: #23262f;
      //   }
      //   50% {
      //     background-color: #085037;
      //   }
      //   70% {
      //     background-color: #23262f;
      //   }
      // }

      // @-webkit-keyframes UploadColumnCard-colorCompleted {
      //   30% {
      //     background-color: #23262f;
      //   }
      //   50% {
      //     background-color: #085037;
      //   }
      //   70% {
      //     background-color: #23262f;
      //   }
      // }
    }

    &.configurable {
      animation: UploadColumnCard-colorConfigurable 4s linear 0s infinite;
      -webkit-animation: UploadColumnCard-colorConfigurable 4s linear 0s infinite
        alternate;

      border-radius: calc(#{$padding} / 2);
      border: 1px solid rgba($color: $emphasis, $alpha: 0.5);
      // outline: 1px solid rgba($color: $emphasis, $alpha: 0.5);
      // outline-offset: -1px;

      filter: drop-shadow(0px 3px 3px rgba($color: $emphasis, $alpha: 0.5));

      background: none;

      // @keyframes UploadColumnCard-colorConfigurable {
      //   30% {
      //     background-color: #23262f;
      //   }
      //   50% {
      //     background-color: #15487d;
      //   }
      //   70% {
      //     background-color: #23262f;
      //   }
      // }

      // @-webkit-keyframes UploadColumnCard-colorConfigurable {
      //   30% {
      //     background-color: #23262f;
      //   }
      //   50% {
      //     background-color: #15487d;
      //   }
      //   70% {
      //     background-color: #23262f;
      //   }
      // }
    }

    .UploadColumnCard-bar {
      @include container;
      flex-direction: row;
      justify-content: space-between;

      width: calc(100% - 4em);
      height: 100%;

      flex-grow: 0;

      .UploadColumnCard-rows {
        @include container;
        flex-direction: column;
        justify-content: space-between;

        .UploadColumnCard-topRow,
        .UploadColumnCard-bottomRow {
          @include container;
          flex-direction: row;
          justify-content: space-between;
        }
        .UploadColumnCard-topRow {
          align-items: center;

          height: 2em;

          padding: 15px;

          font-weight: $thin;
        }
        .UploadColumnCard-bottomRow {
          height: 1em;

          padding: 2px 15px;
          font-size: 0.8em;
          opacity: 0.5;
        }
      }

      .UploadColumnCard-percentage {
        @include container;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        width: 4em;
        height: 100%;

        padding-right: 10px;

        .UploadColumnCard-percentNumber {
          margin-top: 20%;
          font-size: 1.2em;
          font-weight: 300;
        }

        .UploadColumnCard-percentSymbol {
          margin-top: 5px;
          font-size: 0.8em;
        }
      }

      .UploadColumnCard-gauge {
        position: absolute;

        height: 3px;
        left: 10px;
        top: calc(60% - 1.5px);

        flex-grow: 0;
        flex-shrink: 0;

        border-radius: 3px;

        overflow: hidden;
        background-color: #171a24;

        > svg {
          display: block;
          width: 100%;
          height: 100%;
          rect {
            transition: width 0.3s;
          }
        }
      }

      .UploadColumnCard-gaugeDot {
        position: absolute;

        width: 0.4em;
        height: 0.4em;
        top: calc(60% - 0.2em);

        border-radius: 50%;

        transition: left 0.3s;
        background-color: dodgerblue;
      }
    }
    .UploadColumnCard-actionsDiv {
      @include container;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;

      width: 5em;

      flex-grow: 0;
      flex-shrink: 0;

      .UploadColumnCard-uploadStart,
      .UploadColumnCard-uploadDiscard {
        @include container;
        display: flex;
        justify-content: center;
        align-items: center;

        width: 2em;
        height: 2em;

        flex-grow: 0;
        flex-shrink: 0;

        text-align: center;
        vertical-align: middle;
        border-radius: 2px;
        // line-height: 1.5em;
        opacity: 1;
        transition: opacity 0.3s;
        cursor: pointer;

        border-radius: 50%;
      }
      .UploadColumnCard-uploadStart {
        color: $completed;
        &:hover,
        &:focus-within {
          border: 2px solid $completed;
        }
      }
      .UploadColumnCard-uploadDiscard {
        color: $failed;
        &:hover,
        &:focus-within {
          border: 2px solid $failed;
        }
      }
    }
  }
}
