.Icon-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
  user-select: none;

  .Icon-svg {
    width: 1em;
    height: 1em;
    font-size: 1.5em;
    display: inline-block;
    user-select: none;
    // flex-shrink: 0;
    vector-effect: non-scaling-stroke;
    &.fill {
      fill: currentColor;
    }
    &.stroke {
      stroke: currentColor;
    }
  }

  .MuiSvgIcon-root {
    font-size: 1.5em;
  }
}
