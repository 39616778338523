@import 'libs/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $background-light: theme('background-2');
  $background-lighter: theme('background-3');
  $active-color: theme('main');
  $icon-font-size: 14px;

  .ScoringMenuButton-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;

    width: 100%;
    height: 48px;
    color: inherit;
    text-decoration: none;
    font-size: 0.9em;

    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 1px;

    flex-grow: 0;
    flex-shrink: 0;

    padding: 10px;

    &[data-active='true'] {
      background-color: $background-light;
      color: $active-color;
    }
    &:focus,
    &:hover {
      outline: 0;
      background-color: $background-lighter;
    }

    &.simple {
      text-transform: none;
      height: 2.5em;
    }
    &.disabled {
      color: #6e6e6e;
      font-weight: 400;
      pointer-events: none;
    }

    > .Icon-wrapper {
      min-width: 1em;
      width: 2em;
      height: 2em;

      border-radius: 50%;
      border: solid 2px currentColor;
      flex-shrink: 0;

      font-size: $icon-font-size;
    }

    > span {
      margin-left: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:first-child {
        margin-left: $icon-font-size;
      }
    }
  }
}
