.Dynamic-datasets-form-inner-wrapper {
  width: 650px;

  .margin {
    margin: 10px 0px 0px 0px !important;
  }

  .inputs-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}
