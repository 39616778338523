@import 'libs/Base.scss';

.ConfusionMatrixChart-wrapper {
  @include container;

  .ConfusionMatrixChart-UserControlWrapper-wrapper {
    @include container;
    flex-direction: column;
    justify-content: flex-start;
  }
  .ConfusionMatrixChart-ConfusionMatrixGraph-wrapper {
    @include container;
    min-height: 0;
    // position: relative;
    // display: flex;
    // flex-direction: row;
    // flex-shrink: 1;
    // flex-grow: 1;

    .ConfusionMatrixChart-ConfusionMatrixGraph-graph {
      @include container;
      flex-direction: column;
      // position: relative;
      // min-width: 85%;
      // width: calc(100% - 10em);
      // width: 100%;
      // height: 100%;
      // flex-grow: 1;
      // flex-shrink: 1;

      .ConfusionMatrixChart-ConfusionMatrixGraph-horizontalAligner {
        @include container;
        flex-direction: row;
        flex-shrink: 1;
        min-height: 0;

        .ConfusionMatrixChart-ConfusionMatrixGraph-graphSVG {
          @include container;
          // position: absolute;
          // height: 100%;
          // flex-grow: 1;
          // flex-shrink: 1;

          animation: confusionMatrixSnapMask 0.2s forwards;

          @keyframes confusionMatrixSnapMask {
            0% {
              opacity: 0;
            }
            99% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }

          .ConfusionMatrixChart-ConfusionMatrixGraph-legendLine {
            stroke: currentColor;
            stroke-width: 2;
            vector-effect: non-scaling-stroke;
          }

          g {
            // fill-opacity: 0.3;
            transition: fill-opacity 0.6s;
            .ConfusionMatrixChart-ConfusionMatrixGraph-valueText {
              fill: currentColor;
              fill-opacity: inherit;
              user-select: none;
              cursor: default;
              transform-box: fill-box;
            }
            .ConfusionMatrixChart-ConfusionMatrixGraph-percentText {
              fill: currentColor;
              fill-opacity: inherit;
              user-select: none;
              cursor: default;
              transform-box: fill-box;
            }
            .ConfusionMatrixChart-ConfusionMatrixGraph-rect {
              // fill: currentColor;
              fill-opacity: inherit;
            }

            // &:hover {
            //   .ConfusionMatrixChart-ConfusionMatrixGraph-valueText,
            //   .ConfusionMatrixChart-ConfusionMatrixGraph-percentText {
            //     opacity: 1 !important;
            //   }
            // }
          }
        }
      }
    }
  }

  .ConfusionMatrixChart-matrixStrip {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;

    // height: 100%;
    // width: 7em;

    flex-shrink: 0;

    padding: 5px;

    overflow: hidden;

    .ConfusionMatrixChart-matrixDiv {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 7em;
      height: 7em;

      flex-shrink: 0;

      padding: 5px;

      backdrop-filter: blur(2px);

      &.selected {
        border: solid 1px dodgerblue;
      }

      .ConfusionMatrixChart-ConfusionMatrixSimpleGraph-wrapper {
        display: flex;

        width: 100%;
        height: 100%;

        animation: confusionMatrixSimpleGraphSnapMask 0.2s forwards;

        @keyframes confusionMatrixSimpleGraphSnapMask {
          0% {
            opacity: 0;
          }
          99% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        g {
          // fill-opacity: 0.3;
          transition: fill-opacity 0.6s;
          // .ConfusionMatrixChart-ConfusionMatrixSimpleGraph-valueText {
          //   fill: currentColor;
          //   fill-opacity: inherit;
          //   user-select: none;
          //   cursor: default;
          //   transform-box: fill-box;
          // }
          .ConfusionMatrixChart-ConfusionMatrixSimpleGraph-rect {
            // fill: currentColor;
            fill-opacity: inherit;
          }
        }
      }
      .ConfusionMatrixChart-ConfusionMatrixSimpleGraph-blurOverlayDiv {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 100%;

        top: 0;
        left: 0;

        text-shadow: -1px -1px 1px black, 1px -1px 1px black, -1px 1px 1px black,
          1px 1px 1px black;
        backdrop-filter: blur(1px);

        text-transform: uppercase;
        user-select: none;
        cursor: pointer;
        &:hover {
          background-color: rgba(30, 144, 255, 0.5);
        }
      }
    }
  }

  .ConfusionMatrixChart-noDataDiv {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.3);
    > div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      width: 100%;

      &:first-child {
        height: 1em;

        font-size: 2em;
        font-weight: 600;

        text-transform: uppercase;
      }
      &:last-child {
        height: 2em;

        font-size: 1em;
        font-weight: 300;

        font-variant: small-caps;
        color: gray;
      }
    }
  }
}
