@import 'app/insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  .LogsInfoDialog-wrapper {
    .LogsInfoDialog-content {
      @include container;

      flex-direction: column;
      justify-content: center;

      min-width: 5em;
      min-height: 5em;

      margin: auto;

      > .Spinner-wrapper {
        width: 2em;
        height: 2em;

        margin: auto;
      }
    }

    .MuiDialogActions-root {
      padding: 8px !important;
    }
  }
}
