@import 'app/insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $padding: constant('base-padding');
  $bold: constant('font-weight-3');
  $controls-bar-height: 10em;

  $legend-entry-background: theme('background-1');
  $border-color: theme('border-1');
  $font-color: theme('font-1');
  $disabled: theme('font-2');
  $black: theme('black-1');

  .ScatterChart-wrapper {
    @include container;

    color: $font-color;
    user-select: none;

    .ScatterChart-UserControlWrapper-wrapper {
      @include container;

      flex-direction: column;
      justify-content: flex-start;

      .ScatterChart-UserControlsBar-wrapper {
        @include container;

        justify-content: flex-start;
        align-items: flex-start;

        height: $controls-bar-height;

        z-index: 1;

        > div {
          &:not(:first-child) {
            margin-left: $padding;
          }
        }
      }
    }
  }
  .ScatterChart-ScatterGraph-wrapper {
    @include container;

    min-height: 0;

    user-select: none;

    .ScatterChart-ScatterGraph-loading {
      @include container;
      position: absolute;

      top: 0;
      left: 0;

      padding: $padding;

      overflow: hidden;

      background: linear-gradient(
        76deg,
        rgba(255, 255, 255, 0) 34%,
        rgba(30, 144, 255, 0.1) 52%,
        rgba(30, 144, 255, 0.1) 55%,
        rgba(255, 255, 255, 0) 66%
      );
      border: none;
      background-size: 400% 100%;
      filter: none;

      animation: chartDataLoading 3.5s ease infinite;

      @keyframes chartDataLoading {
        0% {
          background-position-x: 100%;
        }
        100% {
          background-position-x: 0%;
        }
      }

      > .Spinner-wrapper {
        width: 1.2em;
        height: 1.2em;
        margin-top: 0.1em;
        margin-left: calc(#{$padding} / 2);
        margin-bottom: -0.5em;
      }

      > span {
        text-align: right;
        line-height: 1.5em;
        margin-left: $padding;
        margin-bottom: -0.5em;
        max-width: 8em;
      }
    }

    .ScatterChart-ScatterLegend-wrapper {
      @include container;

      flex-direction: column;
      justify-content: flex-start;

      flex-grow: 0;
      flex-shrink: 0;
      padding: calc(#{$padding} / 2);

      overflow: hidden;
      overflow-y: auto;

      .ScatterChart-ScatterLegend-entry {
        width: 100%;
        height: 2em;

        flex-shrink: 0;
        padding: calc(#{$padding} / 2);

        user-select: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        background-color: $legend-entry-background;

        &:not(:first-child) {
          margin-top: calc(#{$padding} / 2);
        }
      }
    }

    .ScatterChart-ScatterGraph-graphSVG {
      @include container;

      // border-color: $border-color !important;

      > g {
        // transition: fill-opacity 0.3s;
        transition-timing-function: linear;
        g {
          .ScatterChart-ScatterGraph-dot {
            fill: currentColor;
            fill-opacity: inherit;
            opacity: 0.3;
          }
          .ScatterChart-ScatterGraph-valueText {
            display: none;
            fill: currentColor;
            fill-opacity: inherit;
            user-select: none;
            cursor: default;
            // transform-origin: center;
            transform-box: fill-box;
            opacity: 0.3;
          }
          &:hover {
            .ScatterChart-ScatterGraph-dot {
              transition: transform 0.3s;
              transform: scale(2);
              transform-box: fill-box;
              transform-origin: center;
              opacity: 1 !important;
            }
            .ScatterChart-ScatterGraph-valueText {
              display: unset;
              opacity: 1 !important;
            }
          }
        }
      }
    }

    .ScatterChart-ScatterGraph-noDataDiv {
      @include container;

      position: absolute;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: 100%;
      height: 100%;

      top: 0;
      left: 0;

      background-color: rgba($color: $black, $alpha: 0.25);

      > div {
        @include container;

        justify-content: center;
        align-items: center;

        width: 100%;
        height: unset;

        flex-grow: 0;

        &:first-child {
          height: 1em;

          font-size: 2em;
          font-weight: $bold;

          text-transform: uppercase;
        }
        &:last-child {
          height: 2em;

          font-size: 1em;
          font-weight: 300;

          font-variant: small-caps;
          color: $disabled;
        }
      }
    }
  }
}
