@import 'insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  .ResourceTable-wrapper {
    width: 100%;
    padding-bottom: 3em;

    > .Table-wrapper {
      height: 100%;

      > .Table-content {
        height: 100%;

        > .Table-Column-wrapper {
          height: 100%;
        }
      }
    }

    .ResourceTable-loader {
      height: 100%;
      // font-size: 45px;
      width: 2em;
      margin: auto;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ResourceTable-headerSortingCellDiv {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      width: 100%;
      height: 100%;

      cursor: pointer;

      > .ResourceTable-textDiv {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 35px;

        > div {
          display: block;
          position: relative;

          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          &:first-child {
            height: 20px;
            line-height: 20px;
            flex-shrink: 0;
            flex-grow: 0;
          }
          &:last-child {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;

            // width: 100%;
            height: 15px;

            flex-shrink: 0;
            flex-grow: 0;
            font-size: 0.8em;

            > span {
              height: 15px;
              line-height: 15px;
              color: gray;
            }
          }
        }
      }

      > .ResourceTable-sortingArrow {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 20px;
        height: 35px;

        top: 0;
        right: 0;

        transition: transform 0.3s;

        &.ascending {
          transform: rotate(180deg);
        }
      }
    }

    .ResourceTable-dropDownHeader {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      width: 100%;
      // width: calc(100% - 20px);
      height: 7px;

      top: calc(100% - 7px);
      left: 0;
      z-index: 2;

      cursor: pointer;
      overflow: hidden;

      background: none;

      transition: none;
      // transition: height 0.3s, top 0.3s, opacity 0s;
      // border: 1px solid rgba(128, 128, 128, 0.5);
      // border-top: none;

      &.visible {
        cursor: inherit;
        flex-direction: column-reverse;
        top: 48px;
        backdrop-filter: blur(5px);
        background-image: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0px,
          rgba(23, 26, 36, 1) 0px,
          rgba(23, 26, 36, 0.6)
        );
        transition-property: height, top, background-image;
        transition-duration: 0.6s, 0s, 0.3s;
        border-bottom: 1px solid rgba(128, 128, 128, 0.5);
      }

      .ResourceTable-stat {
        display: block;

        width: 100%;
        height: 2.5em;

        flex-shrink: 0;
        padding: 5px;
        line-height: calc(2.5em - 10px);

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &:not(:first-child) {
          margin-top: 5px;
        }
      }
      > div:last-child {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        width: 100%;
        height: 9px;

        font-size: 0.7em;

        &:not(.open) {
          margin-bottom: -2px;
        }

        &.open {
          // height: 11px;
          // margin-top: -9px;
          > .Icon-wrapper {
            transform: rotate(180deg);
          }
          .ResourceTable-svgBar {
            top: 0;
          }
        }

        cursor: pointer;

        .ResourceTable-svgBar {
          position: absolute;
          display: flex;

          width: calc(100% - 20px);
          height: 8px;

          margin: 0 auto;
          top: calc(100% - 9px);
          // left: 0;
          z-index: -1;

          // background: none;
        }
      }

      // &:hover {
      //   background-color: rgba(23, 26, 36, 0.9);
      // }
    }
  }
}
