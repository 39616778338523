@import 'app/insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $modal-inner-wrapper-min-width: constant('modal-inner-wrapper-min-width');
  $modal-inner-wrapper-min-height: constant('modal-inner-wrapper-min-height');
  $title-size: constant('font-size-large');
  $font-weight: constant('font-weight-1');

  $color: theme('font-1');

  .AlertDialog {
    max-width: 6000px !important;
    .MuiPaper-root {
      border-radius: 0;
      .AlertDialog-title {
        > h2 {
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          > p {
            font-size: $title-size;
          }
        }
        //   button {
        //     justify-self: flex-end;
        //     margin-left: 20px;
        //   }
      }
      .MuiDialogContent-root {
        min-width: $modal-inner-wrapper-min-width;
        min-height: $modal-inner-wrapper-min-height;
        .MuiTypography-root {
          font-weight: $font-weight;
          color: $color;
        }
      }
    }
  }
}
