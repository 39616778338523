@import 'app/insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $padding: constant('base-padding');

  $background-color: theme('background-2');
  $border-color-top: theme('border-3');
  $border-color-bottom: theme('border-2');
  $fill: theme('border-3');

  .BaseCard-wrapper {
    @include container;

    max-width: calc(100% - 2 * #{$padding});
    max-height: calc(100% - 2 * #{$padding});

    flex-grow: 0;
    padding: $padding;
    margin: $padding;

    overflow: hidden;
    // border-radius: 5px;
    // border-top: 1px solid rgba($color: $border-color-top, $alpha: 0.2);
    // border-left: 1px solid rgba($color: $border-color-top, $alpha: 0.2);
    // border-bottom: 1px solid rgba($color: $border-color-bottom, $alpha: 0.2);
    // border-right: 1px solid rgba($color: $border-color-bottom, $alpha: 0.2);
    filter: drop-shadow(2px 2px 4px $border-color-bottom);
    background-color: rgba($color: $background-color, $alpha: 0.6);
    backdrop-filter: blur(5px);

    .BaseCard-svg {
      @include container;

      position: absolute;

      width: 100%;
      height: 100%;

      top: 0;
      left: 0;
      > defs {
        > radialGradient {
          > stop {
            &:first-child {
              stop-color: $fill;
            }
            &:last-child {
              stop-color: rgba(0, 0, 0, 0);
            }
          }
        }
      }
      > ellipse {
        opacity: 7%;
        backdrop-filter: blur(10px);
      }
    }
  }
}
