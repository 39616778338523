@import 'libs/Base.scss';

.CardLayout-wrapper {
  @include container;

  width: 100%;
  height: 100%;

  flex-grow: 1;
  flex-shrink: 1;

  .CardWrapper-wrapper {
    position: absolute;
    display: flex;

    width: fit-content;
    height: fit-content;

    flex-grow: 0;
    flex-shrink: 1;
    // padding: 10px;

    transition: top 0.6s, left 0.6s;
  }
}
