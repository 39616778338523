@import 'app/insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $padding: constant('base-padding');

  $font-color: theme('font-3');
  $border-1: theme('border-1');
  $emphasis: theme('emphasis-1');

  .AutomationModal-wrapper {
    .AutomationModal-deleteLink {
      text-transform: uppercase;
      color: $font-color;
      text-decoration: underline;
      font-size: 0.9em;
      font-variant: all-small-caps;
      margin-left: 10px;
      cursor: pointer;
    }
    .AutomationModal-content {
      .AutomationModal-activePeriodSwitch {
        margin-top: 20px;
      }

      .AutomationModal-groupLabel {
        margin-top: calc(#{$padding} * 1);
      }

      .AutomationModal-formGroup {
        @include container;

        flex-direction: column;

        width: calc(100% + #{$padding} * 2);

        margin-left: -$padding;
        padding: $padding calc(#{$padding} * 2);
        // padding-top: 0;

        border: 1px solid rgba($color: $border-1, $alpha: 0.05);
        border-radius: calc(#{$padding} / 2);

        &:not(:first-child) {
          margin-top: calc(#{$padding} * 1);
        }
      }
      .AutomationModal-formType {
        flex-direction: row;

        width: 100%;

        margin-top: calc(#{$padding} * 1);
        padding-bottom: $padding;
        // border-bottom: 1px solid rgba($color: $border-1, $alpha: 0.3);
      }
      .AutomationModal-formPeriodicInputDiv {
        @include container;

        align-items: center;

        flex-direction: row;

        margin-top: 8px;
        margin-bottom: 4px;

        > span {
          margin-bottom: 7px;
        }

        .AutomationModal-formTrigger {
          width: 5em;

          margin: 0 $padding;
          > div {
            width: 5em;
          }
        }
      }
    }
  }
}
