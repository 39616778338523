.TrainingWizard-step-three {
  padding-top: 80px !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  h2 {
    margin-top: 22px;
  }

  h3 {
    font-weight: 200;
    margin-right: auto;
    font-size: 1.1em;
    margin-bottom: 20px;
  }

  > form {
    justify-content: flex-start;
    align-items: center;
  }

  .TrainingWizard-stepSection {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    margin-bottom: 10px;

    padding: 15px;
    border: 1px solid rgba($color: #000, $alpha: 0.5);
    background-color: rgba($color: #171a24, $alpha: 0.2);

    > .TrainingWizard-sectionHeader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      width: 100%;

      font-weight: 300;

      .Icon-wrapper {
        margin-left: 10px;
        font-size: 14px;
      }
    }

    > .MuiButtonBase-root {
      margin-top: 10px;
    }

    &.TrainingWizard-split-wrapper {
      padding-bottom: 0;
      > .TrainingWizard-sectionHeader {
        > span {
          display: flex;
          align-items: center;
          > span {
            padding: 0 5px;
            margin-left: 3px;
            font-size: 12px;
            font-weight: 600;
            border-radius: 5px;

            // color: rgb(41, 44, 54);
            color: #2e313a;
            background-color: #ff00ff;
            // background-color: rgba($color: #ff00ff, $alpha: 0.6);

            &:first-child {
              margin-left: 20px;

              background-color: #ff9900;
              // background-color: rgba($color: #ff9900, $alpha: 0.6);
            }
            &:last-child {
              background-color: #00ffcc;
              // background-color: rgba($color: #00ffcc, $alpha: 0.6);
            }
          }
        }
      }
    }
  }

  .TrainingWizard-config-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0px;
    width: 100%;

    > * {
      width: 48%;
      height: 100%;
      display: flex;
    }
    .TrainingWizard-remove-btn {
      display: flex;
      justify-content: center;
      align-items: center;

      align-self: flex-end;
      flex-shrink: 0;

      width: 2em;
      height: 2em;

      font-size: 1.2em;
      border: none;
      color: currentColor;
      background: none;
      cursor: pointer;

      &:hover {
        background-color: dodgerblue;
      }

      .Icon-wrapper:not(:last-child) {
        margin-right: 0.5em;
      }
    }
  }

  .TrainingWizard-no-data-msg {
    margin-top: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.5em;
  }

  .TrainingWizard-alternative-data-msg {
    margin-top: 20px;
  }

  .TrainingWizard-validation-msg {
    margin-top: 20px;
    color: red;
    font-size: small;
  }

  .MuiInputBase-root {
    width: 300px;
    max-width: 100%;
  }
}

.TrainingWizard-sectionInfo {
  display: flex;

  flex-direction: column;

  padding: 10px;

  > span {
    font-size: 12px;
    line-height: 12px;
    font-weight: 300;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    > span {
      font-weight: 400;
      color: #ff00ff;
    }

    &:first-child {
      > span {
        color: #ff9900;
      }
    }
    &:last-child {
      > span {
        color: #00ffcc;
      }
    }
  }
}
