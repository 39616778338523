@import 'insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $padding: constant('base-padding');

  .FileStateInspector-wrapper {
    @include container;

    flex-direction: column;

    .FileStateInspector-header {
      @include container;
      
      justify-content: flex-start;
      align-items: center;

      width: 100%;
      height: 3.5em;

      padding: 10px;

      user-select: none;

      border-bottom: 1px solid gray;
      background-color: #23262f;

      .FileStateInspector-title {
        font-size: 1.4em;
        font-weight: 300;
        text-transform: uppercase;
      }

      .FileStateInspector-closeButton {
        display: flex;
        justify-content: center;
        align-items: center;

        min-width: calc(3.5em - 10px);
        width: calc(3.5em - 10px);
        height: calc(3.5em - 10px);

        font-size: 1em;
        border-radius: 50%;
        border: none;
        background: none;
        margin-left: auto;
        color: currentColor;
        cursor: pointer;

        transition: all 0.3s;
        // &:hover {
        //   background-color: dodgerblue;
        // }
      }
    }

    .FileStateInspector-content {
      @include container;

      padding: $padding;
    }
  }
}
