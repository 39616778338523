@import 'libs/Base.scss';

.RingProgressCard-wrapper {
  @include container;

  width: 150px;
  height: 200px;

  background-color: red;

  .RingProgressCard-content {
    @include container;

    position: absolute;
    flex-direction: column;
    align-items: center;

    width: 150px;
    height: 200px;

    left: 0;
    top: 0;

    transition: height 0.3s;
    border: 1px solid black;
    border-top: none;
    border-left: none;
    overflow: hidden;

    background: linear-gradient(
      0deg,
      rgba(35, 38, 47, 1) 50%,
      rgba(65, 69, 84, 1) 100%
    );

    &.expanded {
      height: 300px;
      // filter: drop-shadow(2px 1px 3px #414554);
    }

    .RingProgressCard-header {
      @include container;
      align-items: center;

      width: 100%;
      height: 2em;

      flex-grow: 0;
      padding: 0 10px;

      background: linear-gradient(
        0deg,
        rgba(35, 38, 47, 1) 0%,
        rgba(65, 69, 84, 1) 100%
      );
    }

    .RingProgressCard-main {
      @include container;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: calc(190px - 4em);
      height: calc(190px - 4em);

      flex-grow: 0;
      margin: 5px;

      > svg {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .RingProgressCard-footer {
      @include container;
      flex-direction: column;
      align-items: center;

      width: 100%;
      height: 2em;

      flex-grow: 0;
      padding: 0 10px;
      font-size: 0.8em;
      transition: height 0.3s;

      overflow: hidden;

      .RingProgressCard-details {
        height: 2em;

        flex-shrink: 0;

        opacity: 0.5;
        transition: opacity 0.3s;
        margin-bottom: -2em;
      }

      &.expanded {
        height: calc(2em + 100px);

        .RingProgressCard-details {
          opacity: 0;
        }
      }
    }
  }
}
