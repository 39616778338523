.PopUpMultiSelect-wrapper {
  > .MuiInputBase-root {
    > .MuiSelect-root {
      padding-right: 2.8em;
    }
  }
}
.PopUpMultiSelect-iconWrapper {
  position: absolute;
  bottom: 0.25em;
  right: 1.5em;
  width: 1.5em;
  height: 1.5em;

  &.disabled {
    color: #444;
    > svg {
      cursor: default !important;
    }
  }
}

.multiselect-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 668px;
  max-height: 415px;
  overflow-y: auto;
  margin: 30px 0px;

  > label {
    display: inline-block;
    width: 160px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
  }
}
.select-btn {
  padding-left: 10px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;

  &:focus,
  &:hover {
    color: #3fa9f5;
  }
}
