.ScoringWizard-wrapper {
  .ScoringWizard-content {
    .ScoringWizard-stepsWrapper {
      .ScoringWizard-step {
        .ScoringWizard-stepContent {
          .ScoringWizard-brainsLoadingWrapper {
            // position: absolute;
            height: 180px;
            width: 180px;
            margin-top: 100px;
          }
          .ScoringWizard-brainsError {
            // position: absolute;
            margin-top: 100px;
            height: 150px;
            width: 100%;

            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            font-size: smaller;

            h3 {
              letter-spacing: 1px;
              font-weight: 300;
              margin-bottom: 0.5em;
            }
          }

          .ScoringWizard-brainsError > h3 {
            color: red;
          }

          .Scoring-Wizard-brainsTable-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            width: 100%;
            height: calc(100% - 200px);

            flex-grow: 1;
            flex-shrink: 1;

            .Table-Column-headerCell {
              background-color: #23262f;
            }

            .Table-Column-rowCell {
              background-color: #171a24;
              height: 2em;
              border-bottom: 1px solid rgba(64, 64, 64, 0.5);
            }
          }
        }
      }
    }
  }
}
