@import 'libs/Base.scss';

.LoginPage-wrapper {
  @include container;
  justify-content: center;
  align-items: center;

  .LoginPage-card-wrapper {
    width: 100%;
    height: 20em;
    padding: 2em 2em 0em 2em;
    border: none;
  }

  .LoginForm-wrapper {
    display: flex;
    flex-direction: column;
    width: 350px;
    border-radius: 17px;
    margin-bottom: -75px;
    // margin-top: -100px;
    // padding: 10px 0 100px;
  }
  .LoginPage-registration-link-wrapper {
    margin: 20px auto 0;

    .LoginPage-registration-link {
      margin-left: 5px;
      text-decoration: none;
      color: #3fa9f5;
    }
  }
  .LoginPage-recovery-link-wrapper {
    margin: 5px auto 0;

    .LoginPage-recovery-link {
      margin-left: 5px;
      text-decoration: none;
      color: #3fa9f5;
      background: transparent;
      border: none;
      font-family: unset;
      font-size: 1em;
      cursor: pointer;
    }
  }
  .logoLines {
    fill: rgba(240, 240, 240, 0.8);
  }
  .logoCircles {
    fill: #3fa9f5;
  }
}
