@import 'insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $width: constant('resource-table-width');
  $padding: constant('base-padding');
  $large-padding: constant('large-padding');
  $ultra-thin: constant('font-weight-0');
  $thin: constant('font-weight-1');
  $bold: constant('font-weight-3');
  $font-small: constant('font-size-small');
  $font-normal: constant('font-size-normal');
  $font-large: constant('font-size-large');
  $font-giant: constant('font-size-giant');
  $font-huge: constant('font-size-huge');
  // $height: constant('header-height');
  // $menu-width: constant('menu-width');
  // $bold: constant('font-weight-3');
  // $normal: constant('font-size-normal');

  $color: theme('font-1');
  $border-1: theme('border-1');
  $section-separator: theme('background-2');
  $background-2: theme('background-2');
  $disabled: theme('disabled');
  $hover: theme('emphasis-1');
  $focus: theme('emphasis-1');
  $accepted: theme('accept-green');
  // $border-1: theme('border-1');
  // $border-2: theme('border-2');

  .AccountPages-section {
    @include container;

    flex-direction: column;

    padding: $padding 0;

    &:not(:last-child) {
      // margin-bottom: $padding;
      border-bottom: 1px solid $section-separator;
    }

    .AccountPages-section-title {
      @include container;

      justify-content: space-between;
      align-items: center;

      height: 1.5em;

      flex-grow: 0;

      padding: 0 $padding;

      font-size: $font-giant;
      line-height: 1.5em;
      font-weight: $thin;
      margin-bottom: 10px;
    }

    > span:not(:first-child) {
      padding: 0 $padding;
      font-size: $font-small;
      font-weight: $thin;
      color: rgba($color: $color, $alpha: 0.5);
      line-height: $font-small;
      white-space: pre;
    }

    .AccountPages-formDiv {
      @include container;

      // margin-top: 20px;
      align-items: center;

      .AccountPages-section-subtitle {
        @include container;

        justify-content: flex-start;

        height: 1.5em;

        flex-grow: 0;

        padding: 0;
        text-transform: uppercase;
        font-variant: all-small-caps;
        font-size: $font-large;
        line-height: 1.5em;
        // font-weight: $thin;
        margin-top: 30px;
        margin-bottom: 0px;
        color: $focus;
        opacity: 0.8;
      }

      .Form-wrapper {
        // justify-content: center;
        padding: 0 $padding;
        > div {
          width: 20em;
          > div {
            width: 20em;
          }
        }
      }
    }
  }

  .ProfilePage-wrapper {
    .BaseCard-wrapper {
      overflow: auto;
    }
  }
  .ProfilePage-wrapper,
  .SubscriptionsPage-wrapper,
  .UsersPage-wrapper,
  .LicencesPage-wrapper {
    @include container;

    justify-content: space-between;

    .BaseCard-wrapper {
      flex-direction: column;

      &:last-child {
        // width: calc(55% - #{$large-padding} / 2 - #{$padding} * 1.5);
        width: 25em;
      }
      &:first-child {
        width: calc(100% - 25em - #{$padding} * 3);
      }

      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  .ProfilePage-avatarDiv {
    @include container;

    height: 16em;
    width: 12em;

    flex-grow: 0;
    flex-shrink: 0;

    margin: 0 $padding;

    border-radius: 10px;
    background-color: rgba($color: $background-2, $alpha: 0.5); // $background-2;

    > span {
      position: absolute;

      width: 100%;

      top: calc(50% - #{$font-giant} / 2);

      text-align: center;
      font-weight: $ultra-thin;
      font-size: $font-giant;
      line-height: $font-giant;
    }

    &:hover,
    &:focus-within {
      .ProfilePage-avatarControls {
        opacity: 1;
      }
    }

    .ProfilePage-avatarControls {
      @include container;

      position: absolute;

      justify-content: flex-end;
      align-items: center;

      height: 3em;

      bottom: 0;

      padding: 0 $padding;

      opacity: 0;
      transition: all 0.3s;

      > button {
        @include container;
        justify-content: center;
        align-items: center;

        width: 2em;
        height: 2em;

        flex-grow: 0;
        flex-shrink: 0;

        color: $color;
        font-size: $font-normal;
        cursor: pointer;
        border-radius: 35%;
        border: none;
        background: none;

        &:hover {
          color: $hover;
        }

        &:focus {
          box-shadow: inset 0px 0px 0 2px $focus;
        }
      }
    }
  }
  .UsersPage-usersTableDiv {
    @include container;

    margin-bottom: $padding;
    padding: 0 $padding;

    overflow: hidden;

    .Table-wrapper {
      .Table-content {
        flex-grow: 1;
        .Table-Column-wrapper {
          .Table-Column-content {
            .Table-Column-headerCell {
              height: 3em;
              background: none;
            }
            .Table-Column-rowCell {
              height: 2.5em;
              font-weight: $thin;
              border-bottom: 1px solid $section-separator;

              padding-top: calc(#{$padding} / 2);
              padding-bottom: calc(#{$padding} / 2);

              > div {
                display: block;

                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
      .Table-footer {
        // border-top: 1px solid $border-1;
        background: none;
      }
    }
  }
  .UsersPage-inviteUserDiv {
    @include container;

    justify-content: space-between;
    align-items: baseline;

    height: unset;

    flex-grow: 0;

    padding: 0 $padding;

    > .MuiButton-root {
      width: 25em;
      height: 2.5em;
      margin-left: $padding;
    }

    .MuiFormHelperText-filled {
      color: $accepted;
    }
  }
  .UsersPage-permissionsDiv {
    @include container;

    flex-direction: column;

    .UsersPage-permissionCheckbox {
      margin: 0;
    }
  }
  .UsersPage-licencesDiv {
    @include container;

    flex-direction: column;

    height: unset;

    padding: $padding;

    flex-grow: 0;

    .UsersPage-licenceWrapper {
      @include container;

      justify-content: space-between;
      align-items: center;

      height: 3em;

      flex-grow: 0;

      padding: $padding;

      user-select: none;

      border-radius: calc(#{$padding} / 2);
      border: 1px solid $section-separator;

      &:not(:first-child) {
        margin-top: $padding;
      }
    }
  }
  .UsersPage-addLicenceButton {
    width: calc(100% - 2 * #{$padding});
    margin: 0 $padding;
  }
  .UsersPage-modalLicencesDiv {
    @include container;

    flex-direction: column;

    .UsersPage-licenceWrapper {
      @include container;

      justify-content: space-between;
      align-items: center;

      height: 3em;

      flex-grow: 0;

      padding: $padding;

      color: $color;
      border-radius: calc(#{$padding} / 2);
      border: 1px solid $section-separator;

      &:not(:first-child) {
        margin-top: $padding;
      }
    }
  }
  .LicencesPage-modalUsersDiv {
    @include container;

    flex-direction: column;

    .LicencesPage-userWrapper {
      @include container;

      justify-content: space-between;
      align-items: center;

      height: 3em;

      flex-grow: 0;

      padding: $padding;

      color: $color;
      border-radius: calc(#{$padding} / 2);
      border: 1px solid $section-separator;

      &:not(:first-child) {
        margin-top: $padding;
      }
    }
  }
  .LicencesPage-noLicencesMessageWrapper {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: solid 2px $background-2;
    color: $disabled;

    font-size: 42px;
    
    > *:not(:first-child){
      margin-top: 20px;
    }
  }
  .SubscriptionsPage-billingInfoDiv {
    @include container;

    flex-flow: wrap;

    align-items: flex-start;

    height: unset;

    flex-grow: 0;

    margin: auto 0;
    padding: $padding;

    > span {
      height: $font-normal;
      margin-top: $padding;
      font-size: $font-normal;
      line-height: $font-normal;
      &.SubscriptionsPage-fieldSpan {
        width: 30%;
        font-weight: $bold;
      }
      &.SubscriptionsPage-valueSpan {
        width: calc(70% - 5em);
        font-weight: $thin;
      }
    }
    > .SubscriptionsPage-changeButton {
      width: 5em;
      height: 2em;
      flex-grow: 1;
    }
  }
  .SubscriptionsPage-subscriptionsDiv {
    @include container;

    padding: $padding;

    background-color: red;
  }
  .SubscriptionsPage-paymentsDiv {
    @include container;

    margin: auto 0;
    padding: 0 $padding;

    overflow: hidden;

    .Table-wrapper {
      .Table-content {
        flex-grow: 1;
        .Table-Column-wrapper {
          .Table-Column-content {
            .Table-Column-headerCell {
              height: 3em;
              background: none;
            }
            .Table-Column-rowCell {
              height: 2.5em;
              font-weight: $thin;
              border-bottom: 1px solid $section-separator;
            }
          }
        }
      }
      .Table-footer {
        border-top: 1px solid $border-1;
        background: none;
      }
    }
  }
}
