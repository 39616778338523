@import 'app/insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $padding: constant('base-padding');
  $font-tiny: constant('font-size-tiny');
  $font-normal: constant('font-size-normal');
  $status-spinner-size: 1.2em;
  $add-box-button-size: 1.5em;

  $background-1: theme('background-1');
  $background-2: theme('background-2');
  $background-3: theme('background-3');

  .Pipeline-wrapper {
    @include container;

    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .Pipeline-shadow {
      position: absolute;

      width: 100%;
      height: 100%;

      opacity: 0.5;
      z-index: 2;

      background-color: black;
    }

    .Pipeline-statusDisplayDiv {
      @include container;

      position: absolute;
      justify-content: flex-start;
      align-items: center;

      height: $status-spinner-size;
      width: 7.5em;

      font-size: $font-tiny;

      top: $padding;
      left: $padding;

      .Pipeline-spinnerDiv {
        width: $status-spinner-size;
        height: $status-spinner-size;

        margin-right: calc(#{$padding} / 2);
      }
    }

    .Pipeline-Tree-wrapper {
      @include container;

      flex-direction: column;
      justify-content: flex-start;

      width: unset;
      height: unset;

      flex-grow: 0;
      margin: 0 auto;

      &.processing,
      &.committing {
        background: linear-gradient(
          76deg,
          rgba(255, 255, 255, 0) 34%,
          rgba(30, 144, 255, 0.1) 52%,
          rgba(30, 144, 255, 0.1) 55%,
          rgba(255, 255, 255, 0) 66%
        );
        border: none;
        background-size: 400% 100%;
        filter: none;

        animation: gradient 3.5s ease infinite;

        @keyframes gradient {
          0% {
            background-position-x: 100%;
          }
          // 80% {
          //   background-position-x: 0%;
          // }
          100% {
            background-position-x: 0%;
          }
        }
      }

      .Pipeline-Tree-pipelineSVG {
        position: absolute;

        .Pipeline-Tree-line {
          stroke-width: 2;
          vector-effect: non-scaling-stroke;
          fill: none;
          stroke: dodgerblue;

          animation: snapMask 0.2s forwards;

          @keyframes snapMask {
            0% {
              opacity: 0;
            }
            99% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
        }
        // .Pipeline-Tree-addBox {
        //   stroke: none;
        //   // stroke-width: 2;
        //   // vector-effect: non-scaling-stroke;
        //   // fill: purple;
        //   cursor: pointer;
        //   user-select: none;
        //   fill: dodgerblue;
        //   rx: 50%;
        //   // stroke: white;
        // }
        // .Pipeline-Tree-addBoxText {
        //   cursor: pointer;
        //   user-select: none;
        //   text-anchor: middle;
        //   color: inherit;
        //   fill: currentColor;
        // }
      }

      .Branch-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        width: 100%;

        .Branch-crown {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: stretch;

          flex-grow: 1;
          flex-shrink: 1;
        }

        .Branch-trunk {
          position: relative;
          display: flex;
          flex-direction: column-reverse;
          justify-content: flex-end;
          align-items: stretch;

          flex-grow: 1;
          flex-shrink: 1;
        }

        .Branch-Node-wrapper {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          flex-shrink: 1;

          .Branch-Node-box {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            z-index: 1;

            width: 100%;
            margin: 0 25px;

            font-size: inherit;
            color: inherit;
            cursor: pointer;

            border: 1px solid black;
            border-radius: 3px;
            border-top: none;
            border-left: none;
            filter: drop-shadow(4px 2px 6px #0d0e13);
            background: linear-gradient(
              0deg,
              rgba(35, 38, 47, 1) 50%,
              rgba(65, 69, 84, 1) 200%
            );

            &:focus {
              border: 1px solid dodgerblue;
            }
            &.selected {
              box-shadow: 0 0 0px 2px dodgerblue;
              // box-shadow: 0 0 3px 0.5px rgba(30, 144, 255, 0.7);
              // box-shadow: 0 0 0 2px dodgerblue;
            }
          }

          .Branch-Node-addBoxButton {
            @include container;

            position: absolute;
            justify-content: center;
            align-items: center;

            width: $add-box-button-size;
            height: $add-box-button-size;

            // top: calc(100% - 3em);
            top: 8em;
            left: calc(50% - 0.8em);
            z-index: 2;

            border-radius: 50%;
            cursor: pointer;
            user-select: none;
            background-color: dodgerblue;

            &:hover,
            &:focus-within {
              .Branch-Node-addBoxOptionList {
                width: calc(8.5em + #{$padding} / 2);
                opacity: 1;
              }
            }
            .Branch-Node-addBoxOptionList {
              @include container;

              position: absolute;
              flex-direction: column;

              width: 0px;
              height: unset;

              left: 100%;

              opacity: 0;
              overflow-x: hidden;
              cursor: default;

              transition: all 0.3s;
              .Branch-Node-addBoxOption {
                @include container;

                align-items: center;

                width: 8.5em;
                height: 2.5em;

                margin-left: calc(#{$padding} / 2);

                font-size: $font-normal;
                border: none;
                background: none;
                color: inherit;
                cursor: pointer;

                &:not(:first-child) {
                  margin-top: calc(#{$padding} / 2);
                }

                &:hover,
                &:focus {
                  .Icon-wrapper {
                    background-color: dodgerblue;
                  }
                  > div {
                    &:last-child {
                      background-color: dodgerblue;
                    }
                  }
                }

                > .Icon-wrapper {
                  width: 2.5em;
                  height: 2.5em;

                  border-radius: 5px;

                  flex-grow: 0;
                  flex-shrink: 0;

                  border: 1px solid dodgerblue;
                  background-color: $background-3;
                }
                > div {
                  &:last-child {
                    @include container;

                    align-items: center;

                    height: 2em;

                    flex-shrink: 1;
                    padding-left: calc(#{$padding} / 2);

                    border-radius: 0 0.5em 0.5em 0;

                    border: 1px solid rgba($color: dodgerblue, $alpha: 0.5);
                    border-left: none;
                    background-color: $background-3;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .Pipeline-Tree-dialogListItem {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
  }
}
