@import 'insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@keyframes contextMenuFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@include themed() {
  $tiny: constant('font-size-tiny');
  $thin: constant('font-weight-1');
  $padding: constant('base-padding');
  // $modal-inner-wrapper-min-width: constant('modal-inner-wrapper-min-width');
  // $modal-inner-wrapper-min-height: constant('modal-inner-wrapper-min-height');
  $inner-margin: 10px;

  $border-color: theme('border-1');
  $hover-color: theme('emphasis-1');
  $disabled: theme('disabled');

  .ResourceViewMenu-wrapper {
    @include container;

    flex-direction: column;
    justify-content: space-between;

    width: calc(4.5em + 4 * #{$inner-margin});
    height: calc(100% - #{$padding});

    margin: calc(#{$padding} / 2) 0;
    flex-grow: 0;

    animation: contextMenuFadeIn 0.3s forwards;

    .ResourceViewMenu-title {
      @include container;

      justify-content: center;
      align-items: center;

      height: 26px;

      flex-grow: 0;
      flex-shrink: 0;

      font-size: $tiny;
      font-weight: $thin;
      text-transform: uppercase;
    }
    .ResourceViewMenu-controlsDiv {
      @include container;

      justify-content: space-evenly;

      height: 1.5em;

      flex-grow: 0;

      .ResourceViewMenu-control {
        @include container;

        justify-content: center;
        align-items: center;

        width: 1.5em;
        height: 1.5em;

        flex-grow: 0;
        flex-shrink: 0;

        border: 1px solid rgba($color: $border-color, $alpha: 0.1);

        cursor: pointer;

        > div {
          font-size: 0.9em;
        }

        &:hover {
          background-color: $hover-color;
        }
        &:focus {
          border-color: $hover-color;
        }

        &.disabled {
          color: $disabled;
          background: none;
          cursor: unset;
          border: none;
        }
      }
    }
  }
  .DatasourcesMenu-wrapper,
  .TransformationsMenu-wrapper,
  .TrainingsMenu-wrapper,
  .ScoringsMenu-wrapper {
    @include container;

    width: unset;
    height: calc(100% - #{$padding});

    margin: calc(#{$padding} / 2) 0;

    flex-grow: 0;

    .DatasourcesMenu-menuGroup,
    .TransformationsMenu-menuGroup,
    .TrainingsMenu-menuGroup,
    .ScoringsMenu-menuGroup {
      @include container;

      flex-direction: column;
      justify-content: space-evenly;

      flex-grow: 0;
      flex-shrink: 0;

      animation: contextMenuFadeIn 0.3s forwards;

      &:not(:first-child) {
        border-left: 1px solid rgba($color: $border-color, $alpha: 0.2);
      }

      .DatasourcesMenu-title,
      .TransformationsMenu-title,
      .TrainingsMenu-title,
      .ScoringsMenu-title {
        @include container;

        justify-content: center;
        align-items: center;

        flex-grow: 0;
        flex-shrink: 0;

        font-size: $tiny;
        font-weight: $thin;
        text-transform: uppercase;
      }

      .DatasourcesMenu-controlsDiv,
      .TransformationsMenu-controlsDiv,
      .TrainingsMenu-controlsDiv,
      .ScoringsMenu-controlsDiv {
        @include container;

        justify-content: space-evenly;

        flex-grow: 0;

        .DatasourcesMenu-control,
        .TransformationsMenu-control,
        .TrainingsMenu-control,
        .ScoringsMenu-control {
          @include container;

          justify-content: center;
          align-items: center;

          flex-grow: 0;
          flex-shrink: 0;

          border: 1px solid rgba($color: $border-color, $alpha: 0.05);

          cursor: pointer;

          > div {
            font-size: 0.9em;
          }

          &:hover {
            background-color: $hover-color;
          }
          &:focus {
            border-color: $hover-color;
          }

          &.disabled {
            color: $disabled;
            background: none;
            cursor: unset;
            border: none;
          }
        }
      }
    }
  }
}
