@import 'insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $padding: constant('base-padding');
  $font-weight-2: constant('font-weight-2');
  $font-normal: constant('font-size-normal');
  $tab-height: 3em;

  $background-2: theme('background-2');
  $disabled: theme('disabled');
  $emphasis: theme('emphasis-1');

  .ChartsPage-wrapper {
    @include container;

    flex-direction: column;

    .ChartsPage-tabDiv {
      @include container;

      justify-content: flex-start;

      height: unset;
      flex-grow: 0;

      margin-bottom: $padding;
      padding: $padding;

      .ChartsPage-tabButton {
        @include container;

        justify-content: center;
        align-items: center;

        width: calc(3 * #{$tab-height});
        height: $tab-height;

        flex-grow: 0;

        font-weight: $font-weight-2;
        font-size: $font-normal;
        cursor: pointer;
        color: inherit;
        border: none;
        background-color: $background-2;

        &:hover {
          background-color: $emphasis;
        }
        &:focus {
          outline: 2px solid $emphasis;
          outline-offset: -3px;
        }

        &[data-active='true'] {
          background-color: $emphasis;
        }

        &.disabled {
          cursor: inherit;
          color: $disabled;
          background-color: $background-2;
        }

        &:not(:first-child) {
          margin-left: $padding;
        }
      }
    }

    .BaseCard-wrapper {
      position: absolute;
      height: calc(100% - 5 * #{$padding} - #{$tab-height});
      top: calc(#{$tab-height} + 3 * #{$padding});
      flex-shrink: 0;
    }
  }
}
