@import 'app/insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $bold: constant('font-weight-3');
  $padding: constant('base-padding');
  $height: 3em;
  $range-height: 3em;

  $background-color: theme('background-2');
  $range-color: theme('background-1');
  $font-color: theme('font-1');
  $disabled: theme('font-2');
  $range-pin: theme('emphasis-1');
  $hover: theme('emphasis-1');
  $hover-font: theme('emphasis-2');

  .Pagination-wrapper {
    @include container;

    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;

    flex-grow: 0;
    flex-shrink: 1;

    background-color: $background-color;

    .Pagination-controlsBar {
      @include container;

      justify-content: flex-end;
      align-items: center;

      width: unset;
      height: $height;

      flex-grow: 0;
      flex-shrink: 1;

      .Pagination-scrollBar {
        @include container;

        position: absolute;
        justify-content: flex-end;
        align-items: center;

        height: 1.5em;

        flex-grow: 1;
        flex-shrink: 1;
        top: 0;
        left: 0;

        transition: top 0.4s;
        
        &:focus,
        &:focus-within {
          top: -1.5em;
        }

        .Pagination-range {
          width: 100%;
          height: 100%;

          margin: 0;
          border-radius: 0;

          outline: none;
          -webkit-appearance: none;
          background-color: $background-color;

          &::-webkit-slider-runnable-track {
            height: 0.3em;
            background: $range-color;
            cursor: pointer;
          }
          &::-webkit-slider-thumb {
            width: 1.2em;
            height: 1.2em;
            border: none;
            border-radius: 50%;
            background: $range-pin;
            margin-top: -5px;
            -webkit-appearance: none;
            cursor: pointer;
          }
          &::-moz-range-track {
            height: 0.3em;
            background: $range-color;
            cursor: pointer;
          }
          &::-moz-range-thumb {
            width: 1.2em;
            height: 1.2em;
            border: none;
            border-radius: 50%;
            background: $range-pin;
            cursor: pointer;
          }
        }
      }

      .Pagination-controlsButton {
        @include container;

        justify-content: center;
        align-items: center;

        width: $height;
        height: $height;

        font-size: 1em;
        flex-shrink: 1;
        padding: $padding;
        font-weight: $bold;
        border-radius: 0;

        border: none;
        background-color: $background-color;
        color: $font-color;

        &:hover:not(:disabled) {
          background-color: $hover;
          color: $hover-font;
          cursor: pointer;
        }
        &:focus {
          border: 1px solid $hover;
        }
        &:disabled {
          color: $disabled;
        }
      }
      .Pagination-controlsInput {
        @include container;

        justify-content: center;
        align-items: center;

        width: $height;
        height: $height;

        font-size: 1em;
        flex-shrink: 1;

        background-color: $background-color;

        .Pagination-input {
          width: 100%;
          height: 100%;

          font-weight: $bold;

          text-align: center;
          outline: none;
          border: none;
          color: inherit;
          background-color: $background-color;

          &:focus {
            background-color: $range-color;
            color: $hover;
          }
        }
      }

      &:hover {
        .Pagination-scrollBar {
          top: -1.5em;
        }
      }
    }
  }
}
