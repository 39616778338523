@import 'app/insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $padding: constant('base-padding');
  $font-tiny: constant('font-size-tiny');
  $font-large: constant('font-size-large');
  $thin: constant('font-weight-1');
  $actionButtonSize: 1.5em;

  $background-1: theme('background-1');
  $background-2: theme('background-2');
  $hover: theme('emphasis-1');
  $focus: theme('emphasis-1');
  $disabled: theme('disabled');

  .PipelineBox-wrapper {
    @include container;

    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: calc(100% - #{$padding});
    height: calc(100% - #{$padding});

    top: 0;
    left: 0;

    margin: calc(#{$padding} / 2);

    user-select: none;
    text-align: center;
    border-radius: calc(#{$padding} / 2);
    transition: none;

    &[box-variation='slot'] {
      border: 1px dashed currentColor;
      cursor: pointer;
      &:focus {
        border: 1px solid $focus;
      }
    }

    > .Icon-wrapper {
      font-size: $font-large;
    }
    > div:nth-child(2) {
      width: 8.5em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .PipelineBox-actionsDiv {
      @include container;

      position: absolute;
      flex-direction: column;
      justify-content: center;

      width: unset;

      left: calc(100% + #{$padding});

      opacity: 0;
      transition: opacity 0.4s;

      cursor: default;

      .PipelineBox-clearDatasetButton,
      .PipelineBox-removeBoxButton,
      .PipelineBox-swapBranchesButton,
      .PipelineBox-configureButton,
      .PipelineBox-downloadButton {
        @include container;

        justify-content: center;
        align-items: center;

        width: $actionButtonSize;
        height: $actionButtonSize;

        flex-grow: 0;

        margin-top: calc(#{$padding} / 2);

        border-radius: 3px;
        cursor: pointer;
        user-select: none;

        &:hover {
          color: $hover;
        }
        &:focus {
          border: 1px solid $focus;
        }
      }

      &:focus-within {
        opacity: 1;
      }
    }

    &:hover {
      .PipelineBox-actionsDiv {
        opacity: 1;
      }
    }

    &.invalid {
      background: linear-gradient(
        76deg,
        rgba(128, 128, 128, 0.05) 0%,
        rgba(128, 128, 128, 0.1) 35%,
        rgba(128, 128, 128, 0.1) 65%,
        rgba(128, 128, 128, 0.05) 100%
      );
      opacity: 0.5;
    }
    &.error {
      background: linear-gradient(
        76deg,
        rgba(255, 0, 0, 0.05) 0%,
        rgba(255, 0, 0, 0.1) 35%,
        rgba(255, 0, 0, 0.1) 65%,
        rgba(255, 0, 0, 0.05) 100%
      );
      filter: drop-shadow(0px 0px 3px red);
      &.processing,
      &.committing {
        background: linear-gradient(
          76deg,
          rgba(255, 255, 255, 0) 34%,
          rgba(30, 144, 255, 0.1) 52%,
          rgba(30, 144, 255, 0.1) 55%,
          rgba(255, 255, 255, 0) 66%
        );
        border: none;
        background-size: 400% 100%;
        filter: none;

        animation: gradient 3.5s ease infinite;

        @keyframes gradient {
          0% {
            background-position-x: 100%;
          }
          100% {
            background-position-x: 0%;
          }
        }
      }
    }
    &.not-ready {
      background: linear-gradient(
        76deg,
        rgba(255, 255, 255, 0) 34%,
        rgba(30, 144, 255, 0.1) 52%,
        rgba(30, 144, 255, 0.1) 55%,
        rgba(255, 255, 255, 0) 66%
      );
      border: none;
      background-size: 400% 100%;
      filter: none;

      animation: gradient 3.5s ease infinite;

      @keyframes gradient {
        0% {
          background-position-x: 100%;
        }
        100% {
          background-position-x: 0%;
        }
      }
    }

    .PipelineBox-statusDiv {
      @include container;

      position: absolute;
      justify-content: flex-end;
      align-items: center;

      height: $actionButtonSize;

      font-size: $font-tiny;
      font-weight: $thin;

      bottom: 0;

      .PipelineBox-spinnerDiv {
        width: $actionButtonSize;
        height: $actionButtonSize;

        line-height: 20px;
        font-size: $font-tiny;

        margin-left: calc(#{$padding} / 2);
        flex-shrink: 0;

        filter: none;
      }
    }
    .PipelineBox-type {
      font-size: $font-tiny;
      margin-top: $padding;
      margin-bottom: calc(-2 * #{$padding});
    }
  }
}
