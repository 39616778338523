* {
  box-sizing: border-box;
  :focus {
    // outline: red solid 5px;
    outline: none;
  }
}

html,
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
  margin: 0;
  padding: 0;
}

body::-webkit-scrollbar,
*::-webkit-scrollbar {
  width: 6.6px;
  height: 6.6px;
}

body::-webkit-scrollbar-track,
*::-webkit-scrollbar-track {
  background-color: transparent;
  -webkit-border-radius: 6.6px;
  border-radius: 6.6px;
}
body,
* {
  scrollbar-color: rgb(63, 169, 245) transparent;
  scrollbar-width: thin;
}
body::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb {
  background: rgb(63, 169, 245);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

body::-webkit-scrollbar-corner,
*::-webkit-scrollbar-corner {
  background-color: transparent;
}

body::-webkit-scrollbar-thumb:window-inactive,
*::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(63, 169, 245, 0.37);
}
