@import 'insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $height: constant('footer-height');

  $color: theme('background-2');

  .Footer-wrapper {
    @include container;

    justify-content: space-between;
    align-items: center;

    width: 100vw;
    height: $height;

    background-color: $color;
  }
}
