@import 'app/insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $padding: constant('base-padding');

  .EditDatasetModal-wrapper {
    $edit-modal-width: 25em;
    $edit-modal-entry-height: 3.5em;

    .MuiDialogTitle-root {
      width: calc(#{$edit-modal-width} + 48px);
      .MuiTypography-root {
        .MuiTypography-root {
          display: inline-block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    .EditDatasetModal-formDiv {
      @include container;

      // min-width: $modal-inner-wrapper-min-width;
      // min-height: $modal-inner-wrapper-min-height;

      .EditDatasetModal-datasetTypeDiv {
        @include container;

        align-items: flex-start;
        justify-content: space-between;

        width: $edit-modal-width;
        height: $edit-modal-entry-height;
        margin-bottom: calc(#{$edit-modal-entry-height} / 2);

        > *:not(:first-child) {
          margin-left: $padding;
        }
      }
      .EditDatasetModal-featureDiv {
        @include container;

        align-items: center;
        justify-content: space-between;

        width: $edit-modal-width;
        height: $edit-modal-entry-height;

        > *:not(:first-child) {
          margin-left: $padding;
          width: 12em;
        }
      }
    }
  }
}
