@import 'libs/Base.scss';

.TableViewCard-wrapper {
  @include container;
  flex-direction: row;

  height: 4em;

  flex-grow: 0;

  padding: 10px;

  cursor: pointer;
  user-select: none;
  border: 1px solid black;
  border-top: none;
  border-left: none;
  overflow: hidden;

  background-color: #23262f;

  .TableViewCard-column {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;
    height: 100%;

    font-size: 1em;

    &:first-child {
      font-size: 1.2em;
    }

    padding: 5px;

    overflow: hidden;
    // white-space: nowrap;
    text-overflow: ellipsis;
    // text-transform: capitalize;
    font-variant: small-caps;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    letter-spacing: 0.07em;
  }
}
