.ScoringWizard-step-three {
  padding-top: 80px !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  h2 {
    margin-top: 22px;
  }

  .ScoringWizard-no-data-msg {
    margin-top: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.5em;
  }

  .ScoringWizard-alternative-data-msg {
    margin-top: 20px;
  }

  .ScoringWizard-action-link {
    color: #3fa9f5;
    cursor: pointer;
    text-decoration: none;
    &:focus {
      text-decoration: underline;
      transform: scale(1.1, 1.1);
    }
  }

  .ScoringWizard-pipelineProcessingWrapper {
    position: relative;
    height: 180px;
    width: 180px;
    margin-top: 100px;

    .ScoringWizard-pipelineProcessingMessage {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      color: #3fa9f5;
      font-weight: 100;
      font-size: 1.5em;
    }
  }

  .ScoringWizard-pipeline-error {
    // position: absolute;
    margin-top: 100px;
    height: 150px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: smaller;

    h3 {
      letter-spacing: 1px;
      font-weight: 300;
      margin-bottom: 0.5em;
    }
  }

  .ScoringWizard-pipeline-error > h3 {
    color: red;
  }

  .MuiInputBase-root {
    width: 300px;
    max-width: 100%;
  }
}
