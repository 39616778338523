@import 'insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $width: constant('menu-width');
  $minimized: constant('minimized-menu-width');
  $padding: constant('base-padding');
  $font-normal: constant('font-size-normal');
  $font-small: constant('font-size-small');
  $font-tiny: constant('font-size-tiny');
  $thin: constant('font-weight-1');
  $simple-height: 2em;

  $color: theme('font-1');
  $disabled: theme('disabled');
  $background-color: theme('background-2');
  $background-color-sub: theme('background-1');
  $border-color: theme('border-2');
  $hover: theme('emphasis-1');
  $active: theme('emphasis-1');

  .Menu-wrapper {
    @include container;

    flex-flow: column;
    justify-content: space-between;

    width: $width;

    flex-grow: 0;
    flex-shrink: 0;

    z-index: 1000;
    padding-top: $padding;

    user-select: none;
    background-color: $background-color;
    // filter: drop-shadow(-5px 0px 10px $border-color);
    transition: width 0.3s ease-out;

    &.collapsed {
      width: $minimized;
      > div > button > .Icon-wrapper > svg {
        transform: rotate(270deg);
      }

      .MenuItem-wrapper {
        .MenuItem-buttonDiv {
          display: none !important;
        }
        &:hover {
          overflow: unset;
          .MenuItem-subMenu {
            position: absolute;

            width: $width;

            left: 100%;
            z-index: 2;
            opacity: 1;
            background-color: $background-color-sub;
            border: 2px solid $background-color;
            border-left: 0;
            &.up {
              bottom: 0;
            }

            // .disabled {
            //   > a {
            //     background-color: $background-color !important;
            //   }
            // }
          }
        }
      }
    }

    > div {
      right: 0;
      > button {
        @include container;

        justify-content: space-between;
        align-items: center;

        height: $minimized;

        padding: $padding;

        color: inherit;
        background: none;
        border: none;
        border-radius: 0;
        cursor: pointer;

        &:focus {
          outline: 2px solid $hover;
          outline-offset: -3px;
        }
        &:hover {
          background-color: $hover;
          color: $color;
        }

        span {
          //   width: 100%;
          flex-shrink: 1;
          height: $font-small;
          vertical-align: middle;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-variant: small-caps;
          font-size: $font-small;
          line-height: $font-small;
          font-weight: $thin;
        }
        > .Icon-wrapper {
          @include container;

          width: calc(#{$minimized} - 2 * #{$padding});
          height: 100%;

          flex-grow: 0;
          flex-shrink: 0;

          font-size: $font-normal;
          > svg {
            transition: all 0.3s;
            transform: rotate(90deg);
          }
        }
      }
    }

    .MenuItem-wrapper {
      @include container;

      flex-direction: column;
      justify-content: flex-start;

      height: $minimized;

      flex-grow: 0;
      user-select: none;
      overflow: hidden;

      transition: height 0.3s;

      &.simple {
        height: $simple-height;
        .MenuItem-main {
          height: $simple-height;
          .MenuItem-textDiv {
            padding-left: calc(3 * #{$padding});
            span {
              height: $font-tiny;
              font-size: $font-tiny;
              line-height: $font-tiny;
            }
          }
        }
      }

      &[data-active='true'] {
        .MenuItem-leftMarker {
          visibility: visible;
        }
        .MenuItem-subMenu {
          opacity: 1;
        }
      }

      .MenuItem-leftMarker {
        @include container;

        position: absolute;
        visibility: hidden;

        width: calc(#{$padding} / 2);

        top: 0;
        left: 0;

        z-index: 1;

        background-color: $active;
      }

      .MenuItem-main {
        @include container;

        height: $minimized;

        top: 0;
        flex-grow: 0;
        flex-shrink: 0;

        text-decoration: none;
        color: $color;

        &[data-active='true'] {
          color: $active;
        }
        &:focus {
          outline: 2px solid $hover;
          outline-offset: -3px;
        }
        &:hover {
          background-color: $hover;
          color: $color;
        }

        .MenuItem-iconDiv {
          @include container;

          justify-content: center;
          align-items: center;

          width: $minimized;

          flex-grow: 0;
          flex-shrink: 0;

          padding: $padding;
        }
        .MenuItem-textDiv {
          @include container;

          align-items: center;

          width: calc(100% - #{$minimized});

          flex-grow: 0;

          padding: $padding;

          span {
            width: 100%;
            height: $font-small;
            vertical-align: middle;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-transform: uppercase;
            font-variant: small-caps;
            font-size: $font-small;
            line-height: $font-small;
            font-weight: $thin;
            letter-spacing: 0.06em;
          }
        }
        .MenuItem-buttonDiv {
          @include container;

          justify-content: center;
          align-items: center;

          width: $minimized;

          flex-grow: 0;
          flex-shrink: 0;

          // padding: $padding;
        }
      }
      .MenuItem-subMenu {
        @include container;

        flex-direction: column;

        height: unset;

        opacity: 0;

        background-color: rgba($color: $background-color-sub, $alpha: 0.5);
        transition: opacity 0.3s;
      }

      &.disabled {
        > a {
          background-color: $background-color-sub !important;
          cursor: default !important;
          color: $disabled !important;
          outline: none !important;
        }
      }
    }

    .Menu-logoutButton {
      @include container;
      justify-content: center;
      align-items: center;

      text-decoration: none;
      color: $color;
      font-size: $font-normal;
      cursor: pointer;
      border: none;
      background: none;

      &:focus {
        outline: 2px solid $hover;
        outline-offset: -5px;
      }
      &:hover {
        background-color: $hover;
        color: $color;
      }
    }
  }
}
