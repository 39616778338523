// .MuiDialog-root {
//   &.Modal {
//     backdrop-filter: blur(2px);
//     > .MuiDialog-container {
//       > .MuiPaper-root {
//         background: none !important;
//         background-color: rgba(23, 26, 36, 0.85) !important;
//         border: 2px solid #2e313a;
//         border-radius: 3px;
//       }
//     }
//   }
// }

.MuiDialog-paperWidthSm {
  max-width: 6000px !important;
}

@import 'app/insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $modal-inner-wrapper-min-width: constant('modal-inner-wrapper-min-width');
  $modal-inner-wrapper-min-height: constant('modal-inner-wrapper-min-height');
  $title-size: constant('font-size-large');
  $font-weight: constant('font-weight-1');

  .Modal {
    max-width: 6000px !important;
    font-family: 'Roboto', 'sans-serif';
    .MuiPaper-root {
      border-radius: 0;
      .Modal-title {
        > h2 {
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          > p {
            font-size: $title-size;
          }
        }
        // button {
        //   justify-self: flex-end;
        //   margin-left: 20px;
        // }
      }
      .MuiDialogContent-root {
        min-width: $modal-inner-wrapper-min-width;
        min-height: $modal-inner-wrapper-min-height;
        .MuiTypography-root {
          font-weight: $font-weight;
        }
      }
    }
  }
}
