@import 'app/insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $font-tiny: constant('font-size-tiny');
  $padding: constant('base-padding');
  $thin: constant('font-weight-1');
  $font-large: constant('font-size-large');

  .UserControlsBar-wrapper {
    @include container;
    justify-content: flex-start;
    align-items: flex-start;

    // height: calc(4em + #{$padding});
    height: 5em;

    flex-grow: 0;
    flex-shrink: 0;

    .UserControlsBar-controlsDiv {
      @include container;

      padding: calc(#{$padding} / 2);

      overflow: hidden;
      overflow-x: auto;

      > div {
        &:not(:first-child) {
          margin-left: calc(#{$padding} / 2);
        }
      }

      .UserControl-wrapper {
        @include container;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

        width: unset;
        height: 4em;

        flex: 0 !important;

        padding: 0 calc(#{$padding} / 2);
        border-radius: 3px;

        > div {
          margin: 0;
          > div {
            width: 12em;
          }
        }

        transition: all 0.1s linear 0.1s;

        // .UserControl-background {
        //   position: absolute;
        //   width: 100%;
        //   height: 100%;
        //   top: 0;
        //   left: 0;
        //   opacity: 0.75;
        // }

        .UserControl-title {
          @include container;

          justify-content: space-between;

          height: 1em;

          flex-shrink: 0;

          > .Spinner-wrapper {
            width: 1.2em;
            height: 1.2em;
            margin-top: 0.1em;
            margin-left: calc(#{$padding} / 2);
            margin-bottom: -0.5em;
          }

          > span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            text-align: right;
            line-height: 1.5em;
            margin-left: auto;
            margin-bottom: -0.5em;
            max-width: 8em;
          }
        }
      }
    }
    .UserControlsBar-infoDiv {
      @include container;

      justify-content: center;
      align-items: center;

      width: 5em;
      height: 100%;

      flex-shrink: 0;

      padding: calc(#{$padding} / 2) 0;
      margin-left: auto;

      > .Icon-wrapper {
        font-size: $font-large;
      }
    }
  }

  .UserControlsBar-tooltipInfo {
    @include container;

    flex-direction: column;

    // padding: calc(#{$padding} / 2);
    padding: 0;

    > span {
      padding: calc(#{$padding} / 2);
      border-radius: calc(#{$padding} / 2);
      border: 1px solid currentColor;
      font-size: $font-tiny;
      line-height: $font-tiny;
      font-weight: $thin;
      &:not(:last-child) {
        margin-bottom: $padding;
      }
    }
  }

  .UserControlsBar-tooltip {
    background: none;
    padding: 0;
  }
}
