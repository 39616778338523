@import 'insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $width: constant('resource-table-width');
  $font-normal: constant('font-size-normal');
  $font-large: constant('font-size-large');
  $padding: constant('base-padding');
  $thin: constant('font-weight-1');
  $normal: constant('font-weight-2');
  $bold: constant('font-weight-3');
  $minWidth-name: 5em;
  $columnWidth-date: 11em;
  $columnWidth-status: 7.75em;
  $columnWidth-actions: 2.25em;
  $height: 4em;
  $spinner-size: 128px;

  $background-color: theme('background-2');
  $border-1: theme('border-1');
  $border-2: theme('border-2');
  $hover: theme('emphasis-1');
  $focus: theme('emphasis-1');
  $emphasis: theme('emphasis-1');
  $error: theme('reject-red');

  .TrainingTableHeader-wrapper {
    @include container;

    align-items: center;

    height: 2em;

    flex-grow: 0;
    flex-shrink: 0;

    padding: 0 $padding;
    margin-bottom: $padding;

    cursor: default;
    user-select: none;

    .TrainingTableHeader-cell {
      @include container;

      justify-content: space-between;
      align-items: center;

      flex-shrink: 0;

      text-transform: lowercase;
      font-variant: small-caps;
      font-weight: $thin;
      letter-spacing: 0.06em;

      cursor: pointer;

      border-bottom: 1px solid rgba($color: $border-1, $alpha: 0.4);

      &:focus-within {
        border-bottom: 3px solid $focus;
      }

      > .Icon-wrapper {
        margin-right: calc(3 * #{$padding});
      }
      &.ascending {
        > .Icon-wrapper {
          transform: rotate(180deg);
        }
      }
      &.TrainingTableHeader-name {
        min-width: $minWidth-name;
        padding-left: $padding;
        flex-shrink: 1;
      }
      &.TrainingTableHeader-date {
        width: $columnWidth-date;
        flex-grow: 0;
      }
      &.TrainingTableHeader-status {
        width: $columnWidth-status;
        flex-grow: 0;
      }
      &.TrainingTableHeader-actions {
        justify-content: flex-end;
        width: $columnWidth-actions;
        flex-grow: 0;
        cursor: unset;
      }
    }
  }
  .TrainingTableEntry-wrapper {
    @include container;
    @include focusRipple(10em);

    flex-direction: row;
    align-items: center;

    height: $height;

    flex-grow: 0;
    flex-shrink: 0;

    padding: $padding;
    margin-top: $padding;
    font-size: $font-normal;

    border: 1px solid rgba($color: $border-2, $alpha: 0.4);
    background-color: $background-color;
    cursor: pointer;
    overflow: hidden;

    &:first-child {
      margin-top: 0;
    }

    &.selected {
      border-color: $hover;
    }

    .TrainingTableEntry-cell {
      @include container;

      align-items: center;

      height: calc(#{$height} - 2 * #{$padding});

      flex-grow: 0;
      flex-shrink: 0;

      span {
        width: 100%;
        vertical-align: middle;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-variant: small-caps;
        font-size: $font-normal;
        line-height: $font-normal;
        font-weight: $thin;
        letter-spacing: 0.06em;
      }
      &.TrainingTableEntry-name {
        @include container;

        min-width: $minWidth-name;
        flex-shrink: 1;
        padding: 0 $padding;
        overflow: hidden;

        span {
          text-transform: capitalize;
          font-size: 1.1em;
          line-height: 1.1em;
        }
      }
      &.TrainingTableEntry-date {
        @include container;

        width: $columnWidth-date;

        flex-grow: 0;

        span {
          font-size: 0.9em;
          line-height: 0.9em;
        }
      }
      &.TrainingTableEntry-status {
        @include container;

        width: $columnWidth-status;

        flex-grow: 0;

        user-select: none;

        span {
          font-size: 0.9em;
          line-height: 0.9em;
        }
      }
      &.TrainingTableEntry-actions {
        @include container;

        justify-content: flex-end;

        width: $columnWidth-actions;

        flex-grow: 0;

        user-select: none;

        .TrainingTableEntry-actionButton {
          display: flex;
          justify-content: center;
          align-items: center;

          width: 2.25em;
          // height: calc(#{$columnWidth-actions} / 4);
          height: 2.25em;

          font-size: $font-normal;
          border-radius: 35%;
          border: none;
          color: currentColor;
          background: none;
          cursor: pointer;

          &:hover {
            background-color: $hover;
          }
          &:focus {
            box-shadow: inset 0px 0px 0 2px $hover;
          }
        }
      }
    }
    &:hover {
      .TrainingDetails-wrapper {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .TrainingDetails-wrapper {
    position: fixed;
    visibility: hidden;

    flex-direction: column;

    width: 25em;
    height: fit-content;
    max-height: unset;

    top: 0;
    left: 100%;

    margin: 0 $padding;
    opacity: 0;

    transition-delay: 0.3s;
    transition-duration: 0.1s;
    transition-property: opacity;

    .TrainingDetails-title {
      @include container;

      justify-content: flex-start;
      align-items: center;

      height: 2em;

      padding: $padding;
      margin: $padding;

      .Icon-wrapper {
        margin-right: $padding;
        color: $emphasis;
      }
    }
    .TrainingDetails-content {
      @include container;

      flex-direction: column;

      height: fit-content;

      padding: $padding;

      .TrainingDetails-datasources,
      .TrainingDetails-objectives {
        @include container;

        flex-direction: column;
        justify-content: flex-start;

        height: fit-content;

        margin-bottom: $padding;

        span {
          @include container;

          flex-direction: row;
          justify-content: flex-start;

          font-weight: $bold;

          &:not(:first-child) {
            font-weight: $normal;
          }

          span {
            @include container;

            display: inline-block;

            width: fit-content;

            flex-grow: 0;

            margin-left: $padding;
            font-weight: $thin;

            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &.TrainingDetails-error {
              color: $error;
              font-weight: $normal;
            }
          }
        }
      }
      .TrainingDetails-setup {
        @include container;

        flex-direction: column;
        justify-content: flex-start;

        height: fit-content;

        margin-bottom: $padding;

        font-weight: $bold;

        span {
          @include container;

          flex-direction: row;
          justify-content: flex-start;

          font-weight: $bold;

          &:not(:first-child) {
            font-weight: $normal;
          }

          span {
            @include container;

            width: fit-content;

            flex-grow: 0;

            margin-left: $padding;
            font-weight: $thin;

            &.ScoringDetails-error {
              color: $error;
              font-weight: $normal;
            }
          }
        }
      }
    }
  }
  .TrainingCard-wrapper {
    flex-direction: column;
    align-items: center;

    width: 15em;
    height: 20em;

    padding: 0;

    .TrainingCard-header {
      @include container;

      flex-direction: column;
      justify-content: flex-end;
      align-items: center;

      height: 6em;

      flex-grow: 0;
      flex-shrink: 0;

      // background: linear-gradient(
      //   0deg,
      //   rgba($color: $emphasis, $alpha: 0.3) 0%,
      //   rgba(0, 0, 0, 0) 120%
      // );
      background-color: $background-color;
    }
    .TrainingCard-spinner {
      @include container;

      position: absolute;

      width: $spinner-size;
      height: $spinner-size;

      top: 2em;
      margin: 0 auto;

      border-radius: 50%;

      span {
        @include container;

        position: absolute;
        justify-content: center;
        align-items: center;

        width: $spinner-size;
        height: $spinner-size;

        top: 0;
        left: 0;

        vertical-align: middle;
        font-variant: small-caps;
        font-size: $font-large;
        line-height: $font-large;
        font-weight: $thin;
        letter-spacing: 0.06em;
      }
    }
  }
}
