@import 'app/insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $padding: constant('base-padding');
  $font-tiny: constant('font-size-tiny');
  $font-small: constant('font-size-small');
  $font-normal: constant('font-size-normal');
  $font-large: constant('font-size-large');
  $font-giant: constant('font-size-giant');

  $background-1: theme('background-1');
  $background-2: theme('background-2');
  $border-1: theme('border-1');
  $hover: theme('emphasis-1');
  $focus: theme('emphasis-1');
  $error: theme('reject-red');
  $color-disabled: theme('disabled');

  .ConfigModal-content {
    @include container;

    flex-direction: column;

    .ConfigBox-addRuleButtonsDiv {
      @include container;

      justify-content: center;
      align-items: center;

      height: 3em;

      font-size: $font-normal;
      margin-top: calc(#{$padding} / 2);

      &.extended {
        flex-direction: column;

        height: calc(3 * (4em + #{$padding}) + 3em);

        > .ConfigBox-addRuleButtonsSection {
          @include container;

          flex-flow: row wrap;
          justify-content: center;
          align-items: center;

          height: 3em;

          flex-grow: 0;
          flex-shrink: 0;

          // border: 1px solid rgba($color: $border-1, $alpha: 0.2);
          // border-radius: calc(#{$padding} / 2);

          // &:first-child {
          //   // margin-bottom: $padding;
          //   border: none;
          //   margin: 0 auto;
          // }
          &:not(:first-child) {
            width: calc(100% + #{$padding});
            height: calc(3 * (4em + #{$padding}));

            justify-content: flex-start;

            // margin-top: calc(#{$padding} / 2);
          }
        }
        .CalculationBoxFinancialFunctions-financialFunctionsGroup {
          @include container;

          align-items: center;

          width: calc(50% - #{$padding});
          height: 4em;

          margin: calc(#{$padding} / 2);
          padding: 0 $padding;

          flex-grow: 0;

          border-radius: calc(#{$padding} / 2);
          border: 1px solid rgba($color: $border-1, $alpha: 0.2);

          > .CalculationBoxFinancialFunctions-financialFunctionsGroupLabel {
            position: absolute;
            left: $padding;
            // top: calc(-#{$padding} / 2);
            top: -7px;
            height: calc(#{$font-tiny} + 6px);
            padding: 3px;
            background-color: $background-2;
            font-size: $font-tiny;
            line-height: $font-tiny;
            border: 1px solid rgba($color: $border-1, $alpha: 0.1);
            border-radius: 3px;
          }
          > .MuiTextField-root {
            margin-right: $padding;
          }
        }
      }

      > .ConfigBox-addRuleButtonsSection {
        @include container;

        flex-flow: row;
        justify-content: center;
        align-items: center;

        height: 3em;

        flex-grow: 0;
        flex-shrink: 0;
      }

      .ConfigBox-addRuleButton {
        @include container;

        justify-content: center;
        align-items: center;

        width: unset;
        height: 2em;

        flex-grow: 0;

        margin: 0 calc(#{$padding} / 2);

        cursor: pointer;
        font-size: $font-normal;
        line-height: $font-normal;
        border: 1px solid rgba($color: $border-1, $alpha: 0.5);
        border-radius: calc(#{$padding} / 2);
        color: inherit;
        background: none;

        &:hover:not(.disabled) {
          background-color: $hover;
        }
        &:focus {
          border: 1px solid $focus;
        }
        &.disabled {
          cursor: unset;
          color: $color-disabled;
          border-color: $color-disabled;
        }
      }
    }
  }

  .DatasetBox-config-content-wrapper {
    @include container;

    flex-direction: column;
    align-items: center;

    max-height: calc(19.5em + 20px);

    overflow: auto;

    .DatasetBox-config-content-DatasetRule-wrapper {
      @include container;

      align-items: center;

      height: 5.2em;

      flex-grow: 0;
      flex-shrink: 0;

      font-size: $font-tiny;
      padding: $padding;

      border: 1px solid rgba($color: $border-1, $alpha: 0.2);
      border-radius: calc(#{$padding} / 2);
      background-color: rgba($color: $background-1, $alpha: 0.2);

      &:not(:first-child) {
        margin-top: calc(#{$padding} / 2);
      }

      > span {
        &:first-child {
          max-width: 12em;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        &:last-child {
          margin-left: 10px;
        }
        &:nth-child(2) {
          margin-left: auto;
        }
      }
    }
  }
  .JoinBox-config-content-wrapper {
    @include container;

    flex-direction: column;
    justify-content: center;

    // .JoinBox-config-content-joinSVG {
    //   @include container;
    //   position: absolute;

    //   width: 100%;
    //   height: 100%;

    //   top: 0;
    //   left: 0;

    //   .JoinBox-config-content-line,
    //   .JoinBox-config-content-curve {
    //     stroke-width: 2;
    //     stroke: rgba($color: $background-1, $alpha: 0.5);
    //     fill: none;
    //     vector-effect: non-scaling-stroke;
    //   }
    // }
    > div {
      @include container;

      justify-content: space-evenly;
      align-items: center;

      height: 40%;

      flex-grow: 0;

      &:last-child {
        > div {
          width: 5em;
          > div {
            width: 5em;
          }
        }
      }

      .JoinBox-config-content-removePairButton {
        width: 2em;
        height: 2em;

        min-width: 0;

        margin: auto 0;
        margin-left: $padding;
      }
    }
  }
  .FilterBox-config-content-wrapper {
    @include container;

    flex-direction: column;
    align-items: center;

    max-height: calc(19.5em + 20px);

    overflow: auto;

    .FilterBox-config-content-RuleGroup-wrapper {
      @include container;

      flex-direction: column;

      padding: $padding;

      border: 1px solid rgba($color: $border-1, $alpha: 0.2);
      border-radius: calc(#{$padding} / 2);
      background-color: rgba($color: $background-1, $alpha: 0.2);

      &:not(:first-child) {
        margin-top: calc(#{$padding} / 2);
      }

      // &:hover {
      //   background-color: rgba($color: dodgerblue, $alpha: 0.2);
      // }
            &.connecting {
        background-color: rgba($color: dodgerblue, $alpha: 0.2);
        border: 1px solid dodgerblue;
      }
      &.available {
        cursor: pointer;
        * {
          cursor: pointer !important;
        }
        &:hover,
        &:focus {
          border: 1px solid dodgerblue;
        }
        &:hover {
          background-color: rgba($color: dodgerblue, $alpha: 0.6);
        }
      }
      .FilterBox-config-content-RuleGroup-header {
        @include container;

        justify-content: space-between;

        margin-bottom: calc(#{$padding} / 2);

        .FilterBox-config-content-FilterRule-buttonDiv {
          @include container;

          justify-content: flex-end;
        }
        span {
          font-size: $font-small;
        }
        > label {
          height: calc(#{$padding} * 2);
          > span {
            margin-right: $padding;
          }
        }
      }
    }

    .FilterBox-config-content-FilterRule-wrapper {
      @include container;

      justify-content: space-between;
      align-items: center;

      height: 5.2em;

      flex-grow: 0;
      flex-shrink: 0;

      font-size: $font-tiny;
      padding: $padding;

      border: 1px solid rgba($color: $border-1, $alpha: 0.2);
      border-radius: calc(#{$padding} / 2);
      background-color: rgba($color: $background-1, $alpha: 0.2);

      &:not(:first-child) {
        margin-top: calc(#{$padding} / 2);
      }

      &.connecting {
        background-color: rgba($color: dodgerblue, $alpha: 0.2);
        border: 1px solid dodgerblue;
      }
      &.available {
        cursor: pointer;
        * {
          cursor: pointer !important;
        }
        &:hover,
        &:focus {
          border: 1px solid dodgerblue;
        }
        &:hover {
          background-color: rgba($color: dodgerblue, $alpha: 0.6);
        }
      }

      > .FilterBox-config-content-FilterRule-description,
      > .FilterBox-config-content-FilterRule-application {
        @include container;

        align-items: center;
      }
      > .FilterBox-config-content-FilterRule-description {
        > div {
          margin-left: 5px;
          > div {
            font-size: $font-tiny;
            text-align: start;
          }
          &.method,
          &.text {
            width: 8em;
            > div {
              width: 8em;
            }
          }
          &.invalid {
            width: 10em;
            > div {
              width: 10em;
            }
          }
          &.numeric,
          &.numeric-value {
            width: 3em;
            > div {
              width: 3em;
            }
          }
          &.date {
            width: 5em;
            > div {
              width: 5em;
            }
          }
          &.date-value {
            width: 13em;
            ::-webkit-calendar-picker-indicator {
              margin: 0;
            }
            > div {
              width: 13em;
              font-size: $font-tiny;
              text-align: start;
            }
          }
        }
        > label {
          &.including,
          &.whole {
            width: 8em;
            margin: 0;
            margin-top: 3px;
            margin-left: calc(#{$padding} / 2);
            text-align: left;
            > span {
              padding: 0;
              font-size: $font-tiny;
              line-height: $font-tiny;
            }
          }
        }
        > span:not(:first-child) {
          margin-left: 5px;
        }
      }
      > .FilterBox-config-content-FilterRule-application {
        width: 16em;
        flex-grow: 0;
        flex-shrink: 0;
        // > form {
        > div {
          margin-top: -3px;
          > label {
            font-size: $font-tiny;
            margin-top: -3px;
          }
          > div {
            font-size: $font-tiny;
            margin-top: 15px;
            text-align: start;
          }
        }
        > .PopUpMultiSelect-iconWrapper {
          font-size: $font-normal;
          right: 1em;
        }
        // }
      }
    }
    .FilterBox-config-content-FilterRule-button {
      @include container;
      
      justify-content: center;
      align-items: center;

      width: 2em;
      height: 2em;
      
      flex-grow: 0;
      flex-shrink: 0;
      
        margin-left: calc(#{$padding} / 2);
        
      font-size: $font-normal;
      color: inherit;
      cursor: pointer;
      border: none;
      background: none;
      border-radius: calc(#{$padding} / 2);

      &.small {
        font-size: $font-small;
      }

      &:hover:not(.disabled) {
        background-color: $hover;
      }
      &:focus:not(.disabled) {
        border: 1px solid $focus;
      }
      &.disabled {
        color: $color-disabled;
                &:hover:not(.disabled) {
          background-color: $hover;
        }
        &:focus:not(.disabled) {
          border: 1px solid $focus;
        }
        &.disabled {
          color: $color-disabled;
        }
      }
    }
  }
  .ImputationBox-config-content-wrapper {
    @include container;

    flex-direction: column;
    align-items: center;

    max-height: calc(19.5em + 20px);

    overflow: auto;

    .ImputationBox-config-content-ImputationRule-wrapper {
      @include container;

      justify-content: space-between;

      height: 5.2em;

      flex-grow: 0;
      flex-shrink: 0;

      font-size: $font-tiny;
      padding: $padding;

      border: 1px solid rgba($color: $border-1, $alpha: 0.2);
      border-radius: calc(#{$padding} / 2);
      background-color: rgba($color: $background-1, $alpha: 0.2);

      &:not(:first-child) {
        margin-top: calc(#{$padding} / 2);
      }

      > .ImputationBox-config-content-ImputationRule-description,
      > .ImputationBox-config-content-ImputationRule-application {
        @include container;

        align-items: center;
      }
      > .ImputationBox-config-content-ImputationRule-description {
        > div {
          width: 8em;

          margin-left: 5px;
          > div {
            width: 8em;
            font-size: $font-tiny;
          }
        }
        > span:not(:first-child) {
          margin-left: 5px;
          text-transform: uppercase;
        }
      }
      > .ImputationBox-config-content-ImputationRule-application {
        width: 16em;
        flex-grow: 0;
        flex-shrink: 0;
        // > form {
        > div {
          margin-top: -3px;
          > label {
            font-size: $font-tiny;
            margin-top: -3px;
          }
          > div {
            font-size: $font-tiny;
            margin-top: 13px;
            text-align: start;
          }
        }
        > .PopUpMultiSelect-iconWrapper {
          font-size: $font-normal;
          right: 1em;
        }
        // }
      }
      > .ImputationBox-config-content-ImputationRule-removeButton {
        @include container;

        justify-content: center;
        align-items: center;

        width: calc(2.5em);
        height: calc(2.5em);

        flex-grow: 0;
        flex-shrink: 0;

        margin-left: $padding;

        font-size: $font-normal;
        color: inherit;
        cursor: pointer;
        border: none;
        background: none;
        border-radius: calc(#{$padding} / 2);

        &:hover {
          background-color: $hover;
        }
        &:focus {
          border: 1px solid $focus;
        }
      }
    }
  }
  .SanitationBox-config-content-wrapper {
    @include container;

    flex-direction: column;
    align-items: center;

    max-height: calc(19.5em + 20px);

    overflow: auto;

    .SanitationBox-config-content-SanitationRule-wrapper {
      @include container;

      justify-content: space-between;

      height: 5.2em;

      flex-grow: 0;
      flex-shrink: 0;

      font-size: $font-tiny;
      padding: $padding;

      border: 1px solid rgba($color: $border-1, $alpha: 0.2);
      border-radius: calc(#{$padding} / 2);
      background-color: rgba($color: $background-1, $alpha: 0.2);

      &:not(:first-child) {
        margin-top: calc(#{$padding} / 2);
      }

      > .SanitationBox-config-content-SanitationRule-description,
      > .SanitationBox-config-content-SanitationRule-application {
        @include container;

        align-items: center;
      }
      > .SanitationBox-config-content-SanitationRule-description {
        > div {
          margin-left: 5px;
          > div {
            font-size: $font-tiny;
            text-align: start;
          }
          &.harmonize {
            width: 6em;
            > div {
              width: 6em;
            }
          }
          &.spaces {
            width: 11em;
            > div {
              width: 11em;
            }
          }
          &.method,
          &.replace,
          &.with {
            width: 7em;
            > div {
              width: 7em;
            }
          }
          &.remove {
            width: 3em;
            > div {
              width: 3em;
            }
          }
          &.position {
            width: 3em;
            margin-top: -3px;
            > label {
              width: 6em;
              font-size: $font-tiny;
              text-align: left;
            }
            > div {
              width: 3em;
              margin-top: 13px;
            }
          }
        }
        > label {
          &.backwards {
            width: 10em;
            margin: 0;
            margin-top: 3px;
            margin-left: 15px;
            text-align: left;
            > span {
              padding: 0;
              font-size: $font-tiny;
              line-height: $font-tiny;
            }
          }
        }
        > span:not(:first-child) {
          margin-left: 5px;
        }
      }
      > .SanitationBox-config-content-SanitationRule-application {
        width: 16em;
        flex-grow: 0;
        flex-shrink: 0;
        // > form {
        > div {
          margin-top: -3px;
          > label {
            font-size: $font-tiny;
            margin-top: -3px;
          }
          > div {
            font-size: $font-tiny;
            margin-top: 13px;
            text-align: start;
          }
        }
        > .PopUpMultiSelect-iconWrapper {
          font-size: $font-normal;
          right: 1em;
        }
        // }
      }
      > .SanitationBox-config-content-SanitationRule-removeButton {
        @include container;

        justify-content: center;
        align-items: center;

        width: calc(2.5em);
        height: calc(2.5em);

        flex-grow: 0;
        flex-shrink: 0;

        margin-left: $padding;

        font-size: $font-normal;
        color: inherit;
        cursor: pointer;
        border: none;
        background: none;
        border-radius: calc(#{$padding} / 2);

        &:hover {
          background-color: $hover;
        }
        &:focus {
          border: 1px solid $focus;
        }
      }
    }
  }
  .CalculationBox-config-content-wrapper {
    @include container;

    flex-direction: column;
    align-items: center;

    max-height: calc(19.5em + 20px);

    overflow: auto;

    .CalculationBox-config-content-CalculationRule-wrapper {
      @include container;

      justify-content: space-between;

      height: 5.2em;

      flex-grow: 0;
      flex-shrink: 0;

      font-size: $font-tiny;
      padding: $padding;

      border: 1px solid rgba($color: $border-1, $alpha: 0.2);
      border-radius: calc(#{$padding} / 2);
      background-color: rgba($color: $background-1, $alpha: 0.2);

      &:not(:first-child) {
        margin-top: calc(#{$padding} / 2);
      }

      > .CalculationBox-config-content-CalculationRule-description,
      > .CalculationBox-config-content-CalculationRule-application {
        @include container;

        align-items: center;
      }
      > .CalculationBox-config-content-CalculationRule-description {
        > div {
          margin-left: 5px;
          > div {
            font-size: $font-tiny;
            text-align: start;
          }
          &.prefix,
          &.suffix {
            width: 4em;
            margin-top: -3px;
            > label {
              font-size: $font-tiny;
              text-align: left;
            }
            > div {
              width: 4em;
              margin-top: 13px;
            }
          }
          &.prefix {
            > div {
              > input {
                text-align: right;
              }
            }
          }
          &.suffix {
            margin-right: 20px;
          }
          &.name,
          &.formula {
            width: 16em;
            margin-top: -3px;
            > label {
              font-size: $font-tiny;
              text-align: left;
            }
            > div {
              width: 16em;
              margin-top: 13px;
            }
          }
          &.number,
          &.slow,
          &.fast,
          &.signal,
          &.time {
            width: 3em;
            margin-top: -3px;
            > label {
              font-size: $font-tiny;
              text-align: left;
            }
            > div {
              width: 3em;
              margin-top: 13px;
            }
          }
          &.string,
          &.volume,
          &.open,
          &.low,
          &.high,
          &.close {
            width: 7em;
            margin-top: -3px;
            > label {
              font-size: $font-tiny;
              text-align: left;
            }
            > div {
              width: 7em;
              margin-top: 13px;
            }
          }
          &.number-wide {
            width: 5em;
            margin-top: -3px;
            > label {
              font-size: $font-tiny;
              text-align: left;
            }
            > div {
              width: 5em;
              margin-top: 13px;
            }
          }
          &.number-very-wide {
            width: 5em;
            margin-top: -3px;
            > label {
              width: 8em;
              font-size: $font-tiny;
              text-align: left;
            }
            > div {
              width: 5em;
              margin-top: 13px;
            }
          }
          &.error {
            > label {
              color: $error;
            }
            > div {
              color: $error;
            }
          }
        }
        > label {
          &.checkbox {
            width: 8em;
            margin: 0;
            margin-top: 3px;
            margin-left: calc(#{$padding} / 2);
            text-align: left;
            > span {
              padding: 0;
              // &:last-child {
              font-size: $font-tiny;
              line-height: $font-tiny;
              // }
            }
          }
        }
        > span:not(:first-child) {
          margin-left: 5px;
        }
      }
      > .CalculationBox-config-content-CalculationRule-application {
        width: 16em;
        flex-grow: 0;
        flex-shrink: 0;
        // > form {
        > div {
          margin-top: -3px;
          > label {
            font-size: $font-tiny;
            margin-top: -3px;
          }
          > div {
            font-size: $font-tiny;
            margin-top: 13px;
            text-align: start;
          }
        }
        > .PopUpMultiSelect-iconWrapper {
          font-size: $font-normal;
          right: 1em;
        }
        // }
      }
      > .CalculationBox-config-content-CalculationRule-removeButton {
        @include container;

        justify-content: center;
        align-items: center;

        width: calc(2.5em);
        height: calc(2.5em);

        flex-grow: 0;
        flex-shrink: 0;

        margin-left: $padding;

        font-size: $font-normal;
        color: inherit;
        cursor: pointer;
        border: none;
        background: none;
        border-radius: calc(#{$padding} / 2);

        &:hover {
          background-color: $hover;
        }
        &:focus {
          border: 1px solid $focus;
        }
      }
    }
  }
  .ResampleBox-config-content-wrapper {
    @include container;

    flex-direction: column;
    align-items: center;

    .ResampleBox-config-content-ResampleRule-wrapper {
      @include container;

      justify-content: space-between;

      height: 5.2em;

      flex-grow: 0;
      flex-shrink: 0;

      font-size: $font-tiny;
      padding: $padding;

      border: 1px solid rgba($color: $border-1, $alpha: 0.2);
      border-radius: calc(#{$padding} / 2);
      background-color: rgba($color: $background-1, $alpha: 0.2);

      > div {
        margin-top: -3px;
        > label {
          font-size: $font-tiny;
          text-align: left;
        }
        > div {
          margin-top: 13px;
          font-size: $font-tiny;
          text-align: left;
        }
        &:first-child {
          width: 12em;
          > label {
            width: 12em;
          }
          > div {
            width: 12em;
          }
        }
        &:last-child {
          width: 16em;
          > div {
            width: 16em;
          }
        }
      }
    }
  }
  .MappingBox-config-content-wrapper {
    @include container;

    flex-direction: column;
    align-items: center;

    max-height: calc(19.5em + 20px);

    overflow: auto;

    .MappingBox-config-content-MappingRule-wrapper {
      @include container;

      align-items: center;

      height: 5.2em;

      flex-grow: 0;
      flex-shrink: 0;

      font-size: $font-tiny;
      padding: $padding;

      border: 1px solid rgba($color: $border-1, $alpha: 0.2);
      border-radius: calc(#{$padding} / 2);
      background-color: rgba($color: $background-1, $alpha: 0.2);

      &:not(:first-child) {
        margin-top: calc(#{$padding} / 2);
      }
      > span {
        &:first-child {
          max-width: 10em;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        &:last-child {
          margin-left: 10px;
        }
        &:nth-child(3) {
          margin-left: auto;
        }
      }
      > div {
        width: 10em;
        margin-top: -3px;
        margin-left: 10px;
        > label {
          font-size: $font-tiny;
          text-align: left;
        }
        > div {
          width: 10em;
          margin-top: 13px;
          font-size: $font-tiny;
          text-align: left;
        }
      }
    }
  }
}
