@import 'libs/Base.scss';

@mixin shared-style {
  .message-wrapper {
    height: 404px;
    margin-top: 50px;
    text-align: center;
    .big-text {
      font-size: 35px;
    }
    .small-text {
      font-size: 25px;
      display: block;
      margin-top: 20px;
    }
    .controls-wrapper {
      display: flex;
      align-items: baseline;
      justify-content: center;

      > button {
        &:only-child {
          margin-top: 28px;
        }
        &:not(:only-child) {
          margin-left: 10px;
          width: 190px;
          flex-shrink: 0;
        }
      }

      &.PasswordResetPage-passwordRecoveryControls {
        flex-direction: column;
        align-items: center;
        > button {
          margin-top: 10px;
        }
      }
    }
  }
  .loader-wrapper {
    height: 404px;
    width: 350px;
    margin-top: 50px;
    text-align: center;

    .loader-message {
      font-size: 25px;
      display: block;
      margin-bottom: 20px;
    }
  }
  .logoLines {
    fill: rgba(240, 240, 240, 0.8);
  }
  .logoCircles {
    fill: #3fa9f5;
  }
}

.RegistrationPage-wrapper {
  @include container;
  @include shared-style;
  justify-content: center;
  align-items: center;

  .RegistrationPage-card-wrapper {
    width: 100%;
    min-height: 40em;
    padding: 2em 2em 0em 2em;
    border: none;
    align-items: center;
  }

  .RegistrationPageForm-wrapper {
    display: flex;
    flex-direction: column;
    width: 350px;
    border-radius: 17px;
    margin-bottom: -75px;

    .RegistrationPage-login-link-wrapper {
      margin: 20px auto;

      .RegistrationPage-login-link {
        margin-left: 5px;
        text-decoration: none;
        color: #3fa9f5;
      }
    }

    .error-message {
      color: #f44336;
      font-size: small;
    }

    .terms-and-conditions-link {
      margin-left: 5px;
      text-decoration: none;
      color: #3fa9f5;
    }
  }
}

.ConfirmationPage-wrapper {
  @include container;
  @include shared-style;
  justify-content: center;
  align-items: center;

  .Logo-wrapper {
    display: flex;
    flex-direction: column;
    width: 350px;
    border-radius: 17px;
  }
}

.PasswordResetPage-wrapper {
  @include container;
  @include shared-style;
  justify-content: center;
  align-items: center;

  .Logo-wrapper {
    display: flex;
    flex-direction: column;
    width: 350px;
    border-radius: 17px;
  }
}
