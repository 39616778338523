@import 'app/insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $padding: constant('base-padding');

  // $background-color: theme('background-2');
  // $emphasis: theme('emphasis-1');

  .CSVUploader-wrapper {
    @include container;
    // flex-direction: column;
    // align-items: center;

    justify-content: space-between;

    padding: $padding;

    .CSVUploader-uploadCardsDiv {
      @include container;

      flex-direction: column;
      justify-content: space-between;

      width: unset;

      flex-grow: 1;
      flex-shrink: 1;

      transition: width 0.4s;

      .CSVUploader-footer {
        @include container;

        justify-content: flex-end;

        height: unset;

        flex-grow: 0;
        flex-shrink: 0;

        padding: $padding 0;

        >button {
          &:last-child {
            margin-left: $padding;
          }
        }
      }
    }

    .CSVUploader-selectAndDrop {
      @include container;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      // width: 100%;
      // width: calc(50% - #{$padding});
      // height: 250px;

      margin-left: $padding;

      // flex-grow: 1;

      opacity: 0.3;
      transition: all 0.4s;

      // border-radius: 3px;
      border: 1px dashed;
      border-color: currentColor;
      user-select: none;
      cursor: pointer;

      &:hover,
      &.dragging {
        opacity: 1;
      }

      .CSVUploader-dragSpan {
        font-size: 1.5em;
      }
      .CSVUploader-selectSpan {
        margin-top: 10px;
      }
    }

    .UploadProgress-wrapper {
      @include container;
      flex-direction: column;
      align-items: center;

      // width: 100%;
      // width: calc(50% - #{$padding});
      // width: unset;
      flex-grow: 0;
      // padding-top: 5px;

      // .UploadProgress-entity {
      //   @include container;
      //   flex-direction: row;

      //   width: 100%;
      //   height: 24px;
      //   flex-grow: 0;

      //   margin-top: 1px;
      //   border-radius: 1px;

      //   div {
      //     @include container;
      //     flex-direction: row;
      //     justify-content: space-between;

      //     height: 100%;
      //     flex-grow: 1;

      //     background-color: #2e313a;
      //     > span {
      //       display: flex;
      //       align-items: center;
      //       white-space: nowrap;
      //       text-overflow: ellipsis;
      //       &.pulsate {
      //         @-webkit-keyframes pulsate {
      //           0% {
      //             opacity: 1;
      //           }
      //           50% {
      //             opacity: 0.4;
      //           }
      //           100% {
      //             opacity: 1;
      //           }
      //         }
      //         @-moz-keyframes pulsate {
      //           0% {
      //             opacity: 1;
      //           }
      //           50% {
      //             opacity: 0.4;
      //           }
      //           100% {
      //             opacity: 1;
      //           }
      //         }
      //         @keyframes pulsate {
      //           0% {
      //             opacity: 1;
      //           }
      //           50% {
      //             opacity: 0.4;
      //           }
      //           100% {
      //             opacity: 1;
      //           }
      //         }
      //         -webkit-animation: pulsate 2s infinite alternate;
      //         -moz-animation: pulsate 2s infinite alternate;
      //         animation: pulsate 2s infinite alternate;
      //       }
      //       &:last-child {
      //         user-select: none;
      //       }
      //     }

      //     .UploadProgress-gauge {
      //       position: absolute;

      //       height: 100%;
      //       left: 0;
      //       top: 0;

      //       border-radius: 3px;

      //       background-color: greenyellow;
      //       transition: width 0.4s;
      //     }
      //   }
      //   .UploadProgress-uploadButton,
      //   .UploadProgress-discardButton {
      //     @include container;
      //     flex-direction: row;
      //     justify-content: center;
      //     align-items: center;

      //     width: 24px;
      //     height: 24px;
      //     flex-grow: 0;

      //     margin-left: 3px;
      //     border-radius: 50%;

      //     cursor: pointer;
      //   }
      //   .UploadProgress-uploadButton {
      //     background-color: #08875b;
      //   }
      //   .UploadProgress-discardButton {
      //     background-color: #e4002b;
      //   }
      // }
    }
  }

  .UploadProgress-modalContentWrapper {
    @include container;
    flex-direction: row;

    width: 1200px;
    height: 684px;

    .UploadProgress-formAligner {
      @include container;
      flex-direction: column;

      width: 12em;
      height: 100%;

      flex-grow: 0;
      // margin: 0 -12px;
      // padding: 0 12px;
      // padding-bottom: 5px;
      padding: 0 10px;

      // border-bottom: 1px solid white;

      > form {
        flex-direction: row;
      }
    }
    .UploadProgress-tableDiv {
      display: flex;

      justify-content: stretch;
      align-items: flex-start;

      width: calc(100% - 12em - 24px);
      // height: 100%;
      height: fit-content;
      max-height: 100%;

      border: 1px solid gray;

      flex-shrink: 0;
    }
  }
}
