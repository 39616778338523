@import 'app/insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $padding: constant('base-padding');
  $font-thin: constant('font-weight-1');

  $background-color: theme('background-1');
  // $emphasis: theme('emphasis-1');

  .Slider-wrapper {
    @include container;
    flex-direction: column;

    .Slider-svg {
      @include container;

      height: 4em;

      flex-grow: 0;
      flex-shrink: 0;

      .Slider-bar {
        fill: dodgerblue;
      }
      .Slider-step {
        fill: rgba($color: $background-color, $alpha: 0.5);
      }
      .Slider-label {
        fill: currentColor;
        dominant-baseline: middle;
        text-anchor: middle;
        user-select: none;
      }
      .Slider-fadedTick {
        opacity: 0.1;
        fill: currentColor;
        stroke: rgba($color: dodgerblue, $alpha: 1);
        stroke-width: 10px;
        vector-effect: non-scaling-stroke;
      }
      .Slider-dragableTick {
        fill: dodgerblue;
        cursor: pointer;

        &.dragging {
        }
      }
    }
    .Slider-inputs {
      @include container;

      flex-direction: column;

      margin-top: calc(#{$padding} * 2);

      font-weight: $font-thin;
    }
  }
}
