@import 'insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $width: constant('resource-table-width');
  $font-size: constant('font-size-normal');
  $padding: constant('base-padding');
  $thin: constant('font-weight-1');
  $normal: constant('font-weight-2');
  $bold: constant('font-weight-3');
  // $modal-inner-wrapper-min-width: constant('modal-inner-wrapper-min-width');
  // $modal-inner-wrapper-min-height: constant('modal-inner-wrapper-min-height');
  $minWidth-name: 5em;
  $columnWidth-date: 11em;
  $columnWidth-status: 7.75em;
  $columnWidth-actions: 2.25em;
  $height: 4em;
  $dataset-indent: 2em;
  $dataset-height: 2.5em;

  $background-color: theme('background-2');
  $background-darker: theme('background-1');
  $border-1: theme('border-1');
  $border-2: theme('border-2');
  $hover: theme('emphasis-1');
  $focus: theme('emphasis-1');
  $emphasis: theme('emphasis-1');
  $error: theme('reject-red');

  .ScoringTableHeader-wrapper {
    @include container;

    align-items: center;

    height: 2em;

    flex-grow: 0;
    flex-shrink: 0;

    padding: 0 $padding;
    margin-bottom: $padding;

    cursor: default;
    user-select: none;

    .ScoringTableHeader-cell {
      @include container;

      justify-content: space-between;
      align-items: center;

      flex-shrink: 0;

      text-transform: lowercase;
      font-variant: small-caps;
      font-weight: $thin;
      letter-spacing: 0.06em;

      cursor: pointer;

      border-bottom: 1px solid rgba($color: $border-1, $alpha: 0.4);

      &:focus-within {
        border-bottom: 3px solid $focus;
      }

      > .Icon-wrapper {
        margin-right: calc(3 * #{$padding});
      }
      &.ascending {
        > .Icon-wrapper {
          transform: rotate(180deg);
        }
      }
      &.ScoringTableHeader-name {
        min-width: $minWidth-name;
        padding-left: $padding;
        flex-shrink: 1;
      }
      &.ScoringTableHeader-date {
        width: $columnWidth-date;
        flex-grow: 0;
      }
      &.ScoringTableHeader-status {
        width: $columnWidth-status;
        flex-grow: 0;
      }
      &.ScoringTableHeader-actions {
        justify-content: flex-end;
        width: $columnWidth-actions;
        flex-grow: 0;
        cursor: unset;
      }
    }
  }
  .ScoringTableEntry-wrapper {
    @include container;

    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;

    height: $height;

    flex-grow: 0;
    flex-shrink: 0;

    margin-top: $padding;
    font-size: $font-size;
    overflow: hidden;
    transition: height 0.3s;

    border: 1px solid rgba($color: $border-2, $alpha: 0.4);

    &:first-child {
      margin-top: 0;
    }

    &.open {
      border-color: rgba($color: $border-1, $alpha: 0.4);
    }

    &.selected {
      border-color: $hover;
    }

    .ScoringTableEntry-scoringDiv,
    .ScoringTableEntry-scoredDatasourcesDiv {
      @include container;

      .ScoringTableEntry-cell {
        @include container;

        align-items: center;

        flex-grow: 0;
        flex-shrink: 0;

        span {
          width: 100%;
          vertical-align: middle;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-variant: small-caps;
          font-size: $font-size;
          line-height: $font-size;
          font-weight: $thin;
          letter-spacing: 0.06em;
        }
        &.ScoringTableEntry-name {
          @include container;

          min-width: $minWidth-name;
          flex-shrink: 1;
          padding: 0 $padding;
          overflow: hidden;

          span {
            font-size: 1.1em;
            line-height: 1.1em;
          }
        }
        &.ScoringTableEntry-date {
          @include container;

          width: $columnWidth-date;

          flex-grow: 0;

          span {
            font-size: 0.9em;
            line-height: 0.9em;
          }
        }
        &.ScoringTableEntry-status {
          @include container;

          width: $columnWidth-status;

          flex-grow: 0;

          user-select: none;

          span {
            font-size: 0.9em;
            line-height: 0.9em;
          }
        }
        &.ScoringTableEntry-actions {
          @include container;

          justify-content: flex-end;

          width: $columnWidth-actions;

          flex-grow: 0;

          user-select: none;

          .ScoringTableEntry-actionButton {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 2.25em;
            height: 2.25em;

            font-size: $font-size;
            border-radius: 35%;
            border: none;
            color: currentColor;
            background: none;
            cursor: pointer;

            &:hover {
              background-color: $hover;
            }
            &:focus {
              box-shadow: inset 0px 0px 0 2px $hover;
            }
          }
        }
        &.ScoringTableEntry-error {
          span {
            color: $error;
            font-weight: $bold;
            font-variant: normal;
            letter-spacing: unset;
          }
        }
      }
    }
    .ScoringTableEntry-scoringDiv {
      @include focusRipple(10em);

      align-items: center;

      height: $height;

      flex-grow: 0;
      flex-shrink: 0;

      top: -1px;
      padding: $padding;

      background-color: $background-color;

      &.expandable {
        cursor: row-resize;
      }
      &.clickable {
        cursor: pointer;
      }

      .ScoringTableEntry-cell {
        height: calc(#{$height} - 2 * #{$padding});

        .ScoringTableEntry-name {
          span {
            text-transform: capitalize;
          }
        }
      }
    }
    .ScoringTableEntry-scoredDatasourcesDiv {
      flex-direction: column;

      border-top: none;

      > .ScoringTableEntry-scoredDatasource {
        @include container;

        flex-direction: row;
        align-items: center;

        height: $dataset-height;

        flex-grow: 0;
        flex-shrink: 0;

        padding: calc(#{$padding} / 2) $padding;

        background-color: $background-darker;
        cursor: pointer;

        &:not(:first-child) {
          border-top: 1px solid rgba($color: $border-2, $alpha: 0.4);
        }

        &:focus {
          outline: 1px solid $hover;
          outline-offset: -2px;
        }

        &.selected {
          outline: 3px solid $hover;
          outline-offset: -4px;
          background-color: rgba($color: $hover, $alpha: 0.35);
        }

        .ScoringTableEntry-cell {
          .ScoringTableEntry-name {
            span {
              text-transform: lowercase;
            }
          }
        }
      }
    }

    &:hover {
      .ScoringDetails-wrapper {
        visibility: visible;
        opacity: 1;
      }
    }
  }
    .ScoringDetails-wrapper {
      position: fixed;
      visibility: hidden;

      flex-direction: column;

      width: 25em;
      height: fit-content;

      top: 0;
      left: 100%;

      margin: 0 $padding;
      opacity: 0;

      transition-delay: 0.3s;
      transition-duration: 0.1s;
      transition-property: opacity;

      .ScoringDetails-title {
        @include container;

        justify-content: flex-start;
        align-items: center;

        height: 2em;

        padding: $padding;
        margin: $padding;

        .Icon-wrapper {
          margin-right: $padding;
          color: $emphasis;
        }
      }

      .ScoringDetails-content {
        @include container;

        flex-direction: column;

        height: fit-content;

        padding: $padding;

        overflow-y: auto;

        .ScoringDetails-datasources,
        .ScoringDetails-schedule,
        .ScoringDetails-setup {
          @include container;

          flex-direction: column;
          justify-content: flex-start;

          height: fit-content;

          &:not(:last-child) {
            margin-bottom: $padding;
          }

          span {
            @include container;

            flex-direction: row;
            justify-content: flex-start;

            font-weight: $bold;

            &:not(:first-child) {
              font-weight: $normal;
            }

            span {
              @include container;

              width: fit-content;

              flex-grow: 0;

              margin-left: $padding;
              font-weight: $thin;

              &.ScoringDetails-error {
                color: $error;
                font-weight: $normal;
              }
            }
          }
        }
      }
    }
}
