@import 'app/insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $padding: constant('base-padding');
  $font-size-normal: constant('font-size-normal');
  $font-size-small: constant('font-size-small');
  $font-size-large: constant('font-size-large');
  $font-thin: constant('font-weight-1');

  //   $background-color: rgb(21, 22, 30);
  $background-color: rgb(26, 28, 38);
  $background-color-light: rgb(26, 28, 38);
  $border-color: rgb(64, 72, 80);

  .CurrencyGraph-wrapper {
    @include container;

    .CurrencyGraph-sideBar {
      @include container;

      flex-direction: column;

      justify-content: flex-start;
      align-items: center;

      width: 3em;

      flex-grow: 0;
      flex-shrink: 0;

      margin-right: calc(#{$padding} / 2);

      border: 1px solid $border-color;
      background-color: $background-color;

      > .CurrencyGraph-sideBarDivider {
        @include container;

        flex-direction: column;

        align-items: center;

        height: unset;

        flex-grow: 0;
        flex-shrink: 1;

        padding: $padding;

        &:not(:last-child) {
          border-bottom: 1px solid $border-color;
        }

        > .CurrencyGraph-sideBarButton {
          @include container;

          width: 2em;
          height: 2em;

          flex-grow: 0;
          flex-shrink: 0;

          border-radius: 50%;
          border: 3px solid rgba($color: white, $alpha: 0.5);

          &:not(:last-child) {
            margin-bottom: calc(#{$padding} / 2);
          }
        }
      }
    }

    .CurrencyGraph-content {
      @include container;

      flex-direction: column;

      flex-grow: 1;
      flex-shrink: 1;

      //   border: 1px solid $border-color;
      //   background-color: $background-color;

      .CurrencyGraph-header {
        @include container;

        justify-content: flex-start;
        align-items: center;

        height: 2em;

        flex-grow: 0;
        flex-shrink: 0;

        border-bottom: 1px solid $border-color;
        background-color: $background-color;
      }

      .CurrencyGraph-graph {
        @include container;

        flex-grow: 1;
        flex-shrink: 1;
        // background-color: rgba($color: $background-color, $alpha: 0.35);
        background-color: $background-color-light;

        > .CurrencyGraph-infoDiv {
          @include container;

          position: absolute;

          flex-direction: column;

          max-width: 300px;
          height: unset;

          top: 0;
          left: 0;

          padding: $padding;

          flex-grow: 1;
          flex-shrink: 0;

          overflow: hidden;

          > span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-weight: $font-thin;
            &:first-child {
              font-size: $font-size-large;
            }
            &:last-child {
              font-size: $font-size-small;
            }
          }
        }
        > .CurrencyGraph-hoverDiv {
          @include container;

          position: absolute;

          flex-direction: column;

          max-width: 400px;
          height: unset;

          top: 0;
          left: 300px;

          padding: $padding;

          flex-grow: 1;
          flex-shrink: 0;
        }

        > .CurrencyGraph-aligner {
          @include container;

          flex-direction: column;

          .Grid-wrapper {
            position: absolute;
            display: flex;

            width: 100%;
            height: calc(70% - (48 * 0.7) * 1px);

            top: 0;
            left: 0;

            flex-grow: 0;
            flex-shrink: 0;

            overflow: hidden;

            &:not(:first-child) {
              height: calc(30% - (48 * 0.3) * 1px);
              top: calc(70% - (48 * 0.7) * 1px);
            }

            .Grid-grid {
              width: 100%;
              height: 100%;
              > line {
                stroke: rgb(75, 75, 75);
                stroke-width: 0.5;
                vector-effect: non-scaling-stroke;
              }
            }
          }

          > .CurrencyGraph-topGraph {
            @include container;

            flex: 7;

            > .CurrencyGraph-lowHighPath {
              fill: rgba(64, 156, 156, 0.05);
              stroke: rgb(32, 255, 192);
              stroke-width: 0.5;

              vector-effect: non-scaling-stroke;
            }
            > .CurrencyGraph-line {
              fill: none !important;
              stroke: rgb(255, 64, 96);
              stroke-width: 0.5;

              vector-effect: non-scaling-stroke;
            }
            > .CurrencyGraph-horizontal {
              fill: none !important;
              stroke: rgb(255, 160, 64);
              stroke-width: 1;
              stroke-dasharray: 1 3;

              vector-effect: non-scaling-stroke;
            }
            > .CurrencyGraph-barsGroup {
              opacity: 0.4;
              > .CurrencyGraph-bar {
                &.green {
                  fill: rgb(32, 255, 192);
                }
                &.red {
                  fill: rgb(255, 64, 96);
                }
              }
            }
            > .CurrencyGraph-candlesGroup {
              opacity: 0.75;
              .CurrencyGraph-candle {
                &.green {
                  fill: rgb(32, 255, 192);
                }
                &.red {
                  fill: rgb(255, 64, 96);
                }
              }
              .CurrencyGraph-wick {
                fill: none !important;
                stroke-width: 1;

                vector-effect: non-scaling-stroke;
                &.green {
                  stroke: rgb(32, 255, 192);
                }
                &.red {
                  stroke: rgb(255, 64, 96);
                }
              }
            }
          }

          > .CurrencyGraph-bottomGraph {
            @include container;

            flex: 3;

            border-top: 1px solid $border-color;
            border-bottom: 1px solid $border-color;

            > .CurrencyGraph-line {
              fill: none !important;
              stroke: rgb(128, 160, 255);
              stroke-width: 0.7;

              vector-effect: non-scaling-stroke;
            }
            > .CurrencyGraph-bottomRange {
              opacity: 0.2;
              fill: rgb(64, 32, 192);
            }
            > .CurrencyGraph-bottomLine {
              fill: none !important;
              stroke: rgb(255, 224, 192);
              stroke-width: 0.5;
              stroke-dasharray: 6 5;

              vector-effect: non-scaling-stroke;
            }
          }
        }
        > .CurrencyGraph-yAxis {
          @include container;

          flex-direction: column;

          width: 4em;

          flex-grow: 0;
          flex-shrink: 0;

          border-left: 1px solid $border-color;

          .YAxis-wrapper {
            width: 100%;
            height: calc(70% - (48 * 0.7) * 1px);

            flex-grow: 0;
            flex-shrink: 0;

            &:not(:first-child) {
              height: calc(30% - (48 * 0.3) * 1px);
              border-bottom: 1px solid $border-color;
            }
          }
        }
      }

      .CurrencyGraph-footer {
        @include container;

        justify-content: flex-end;
        align-items: center;

        height: 2em;

        flex-grow: 0;
        flex-shrink: 0;

        border-top: 1px solid $border-color;
        background-color: $background-color;
      }
    }
  }
}
