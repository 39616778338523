@import 'insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $background-color: theme('background-1');
  $background-2: theme('background-2');
  $track-border-color: theme('background-3');
  $color-main: theme('main');
  $tab-active: theme('emphasis-1');
  $border-2: theme('border-2');

  $padding: constant('base-padding');
  $header-height: 40px;
  $track-border-width: 2px;
  $loading-icon-size: 1.5em;

  .DatasourceInspector-wrapper {
    position: static;
    height: 100%;
    width: 30px;
    flex-shrink: 0;

    z-index: 1000;

    .DatasourceInspector-body {
      position: absolute;
      top: 0;
      left: 100%;

      max-width: 1080px;
      height: 100%;
      padding: 5px;
      padding-left: 5px + $track-border-width;
      border-left: solid 1px black;
      background: $background-color;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      transition: transform 0.3s;

      &[data-active='true'] {
        transform: translateX(-100%);
      }
    }

    .DatasourceInspector-track-border {
      position: absolute;
      top: 0px;
      left: 0px;
      background-color: $track-border-color;
      height: 100%;
      width: $track-border-width;
    }

    .DatasourceInspector-tab {
      @include focusRipple(20px);
      @include container;

      position: absolute;

      justify-content: center;
      align-items: center;

      width: 2em;
      height: 3em;

      top: 0;
      left: -30px;

      cursor: pointer;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border: 1px solid $track-border-color;
      border-right: none;
      background: linear-gradient(90deg, $background-color 50%, $border-2 200%);

      &[data-tab='active'] {
        z-index: 1;
        background: linear-gradient(
          270deg,
          $background-color 0%,
          $background-2 100%
        );

        .Icon-wrapper {
          color: $tab-active;
        }
      }

      @for $i from 1 through 5 {
        &:nth-child(#{$i}) {
          top: calc(124px + ((#{$i} - 1) * (3em + #{$padding})));
        }
      }
    }

    .DatasourceInspector-track {
      @include focusRipple(40px);
      position: absolute;
      display: flex;
      left: -40px;
      top: 50px;
      justify-content: center;
      align-items: center;

      width: 40px;
      padding: 50px 0 50px 2px;

      cursor: pointer;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-right: solid 1px black;
      background: linear-gradient(0deg, #23262f 50%, #414554 100%);
      filter: drop-shadow(0px 0px 2px #0d0e13);

      .Icon-wrapper {
        transition: transform 0.3s;

        &[data-icon='loading'] {
          color: $color-main;
          font-size: $loading-icon-size;
        }

        &[data-icon='collapse'] {
          width: $loading-icon-size;
          height: $loading-icon-size;
        }
      }
      &[data-loading='false'] [data-icon='loading'] {
        display: none;
      }
      &[data-loading='true'] [data-icon='collapse'] {
        display: none;
      }
      &[data-expanded='true'] [data-icon='collapse'] {
        transform: rotate(180deg);
      }
    }

    .DatasourceInspector-content {
      @include container;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .DatasourceInspector-header {
        position: relative;

        display: flex;
        justify-content: space-between;

        width: 100%;
        margin-bottom: 0.6em;
        margin-top: 0.4em;
        padding-left: 10px;
        padding-right: 10px;

        letter-spacing: 1px;
        font-size: 1.4em;
        font-weight: 100;
        text-transform: uppercase;

        &::after {
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          top: calc(100% + 0.3em);
          left: 0px;
          -moz-background-size: 100% 1px;
          background-size: 100% 1px;
          background-position: 0 0, 0 100%;
          background-repeat: no-repeat;
          background-image: -webkit-gradient(
            linear,
            0 0,
            0 100%,
            from(#5c5d68),
            to(transparent)
          );
          background-image: -webkit-linear-gradient(180deg, #5c5d68, transparent);
          background-image: -moz-linear-gradient(180deg, #5c5d68, transparent);
          background-image: -o-linear-gradient(180deg, #5c5d68, transparent);
          background-image: linear-gradient(90deg, #5c5d68, transparent);
        }

        .download-link {
          display: flex;
          font-size: 18px;
          color: white;
          text-transform: initial;
          background: none;
          border: none;
          font-family: 'Roboto';
          font-weight: 200;
          &:hover {
            color: dodgerblue;
            cursor: pointer;
          }
        }
      }

      .DatasourceInspector-chart-wrapper {
        @include container;

        flex-direction: column;

        width: 60em;
        height: 0;

        > div {
          height: 50%;
          max-height: 50%;
          flex-grow: 0;
          flex-shrink: 0;
        }
      }

      .DatasourceInspector-brains-table-wrapper {
        @include container;

        height: 442px;
        // flex-grow: 0;
        // padding: 5px;
        // margin: 0 -5px;

        // border: solid 1px black;
        // border-left: none;
        // border-right: none;
        // background: $table-background-color;
      }
    }
  }
}
