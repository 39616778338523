@import 'insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $header-height: constant('header-height');
  $menu-width: constant('menu-width');
  $minimized-menu-width: constant('minimized-menu-width');
  $padding: constant('base-padding');

  $background-color: theme('background-1');
  $background-2: theme('background-2');
  $track-border-color: theme('background-3');
  $tab-active: theme('emphasis-1');
  $border-2: theme('border-2');

  .PipelineInspector-wrapper {
    display: flex;
    flex-direction: row;

    width: 100%;
    height: 100%;

    font-family: 'Roboto', sans-serif;

    background-color: $background-color;

    .PipelineInspector-main {
      @include container;
      flex-direction: column;
      // width: 700px;
      // width: 100%;
      flex-grow: 1;
      overflow-y: auto; // TODO: make canvas scrolling
      // padding: 10px;

      .PipelineInspector-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        width: 100%;
        height: 3.5em;

        padding: 10px;

        user-select: none;

        border-bottom: 1px solid gray;
        background-color: #23262f;

        .PipelineInspector-title {
          font-size: 1.4em;
          font-weight: 300;
          text-transform: uppercase;
        }

        .PipelineInspector-closeButton {
          display: flex;
          justify-content: center;
          align-items: center;

          min-width: calc(3.5em - 10px);
          width: calc(3.5em - 10px);
          height: calc(3.5em - 10px);

          font-size: 1em;
          border-radius: 50%;
          border: none;
          background: none;
          margin-left: auto;
          color: currentColor;
          cursor: pointer;

          transition: all 0.3s;
          // &:hover {
          //   background-color: dodgerblue;
          // }
        }
      }

      .PipelineInspector-content {
        display: flex;
        // flex-direction: column;
        justify-content: space-between;
        align-items: center;

        width: 100%;
        max-width: 700px;
        height: calc(100% - 6.5em);

        flex-grow: 1;
        user-select: none;
        overflow: hidden;
      }

      .PipelineInspector-footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        width: 100%;
        height: 3em;

        padding: 10px;

        user-select: none;

        border-top: 1px solid gray;
        background-color: $background-2;

        > button:not(:last-child) {
          margin-right: 10px;
        }
      }
    }

    .PipelineInspector-side {
      @include container;
      width: unset;
      max-width: calc(100% - 700px);
      // flex-grow: unset;
      flex-shrink: 1;
      // overflow: hidden;
      // margin-left: auto;

      // padding: 0 $padding;
      border-color: dodgerblue !important;

      .PipelineInspector-tab {
        @include focusRipple(20px);
        @include container;

        position: absolute;

        justify-content: center;
        align-items: center;

        width: 2em;
        height: 3em;

        top: 0;
        left: -32px;

        cursor: pointer;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border: 1px solid $track-border-color;
        border-right: none;
        // background: linear-gradient(90deg, $background-color 50%, $border-2 200%);

        background-color: $background-color;

        &[data-tab='active'] {
          z-index: 1;
          background: linear-gradient(
            270deg,
            $background-color 0%,
            $background-2 100%
          );

          .Icon-wrapper {
            color: $tab-active;
          }
        }

        @for $i from 1 through 5 {
          &:nth-child(#{$i}) {
            top: calc(5em + (#{$i} - 1) * (3em + #{$padding}));
          }
        }
      }

      .PipelineInspector-table {
        @include container;
        overflow: hidden;
      }
      .PipelineInspector-charts {
        @include container;

        flex-direction: column;

        width: 100vw;

        padding: $padding 0;

        // .PipelineInspector-lineUserControl,
        // .PipelineInspector-scatterUserControl {
        //   @include container;

        //   height: calc(4em + 10px);

        //   flex-grow: 0;
        //   flex-shrink: 0;
        // }

        .LineChart-wrapper,
        .ScatterChart-wrapper {
          // height: calc(50% - 4em - 10px);
          // max-height: calc(50% - 4em);
          height: 50%;
          flex-grow: 0;
          flex-shrink: 0;
        }
      }
    }
  }

  .MuiDialog-root {
    &.PipelineInspector-dialog {
      position: absolute !important;

      // width: calc(100% - 16em - 40px);
      // height: calc(100% - 2.5em - 40px);

      // width: calc(100% - $menu-width);
      // height: calc(100% - $header-height);

      // top: calc(2.5em + 20px) !important;
      // left: calc(16em + 20px) !important;

      // top: $header-height !important;
      // left: $menu-width !important;
      font-family: 'Roboto', sans-serif !important;

      > .MuiDialog-container {
        > .MuiPaper-root {
          // border-radius: 3px !important;
        }
      }

      > .MuiBackdrop-root {
        // opacity: 0 !important;
      }
    }
  }
}
