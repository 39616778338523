@import 'insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $header-height: constant('header-height');
  $padding: constant('large-padding');

  $background-color: theme('background-1');
  $font-color: theme('font-1');
  $line-fill: theme('background-3');
  $circle-fill: theme('emphasis-1');
  $ring-fill: theme('font-1');

  .App-wrapper {
    @include container;
    flex-direction: column;
    justify-content: flex-start;

    background-color: $background-color;
    color: $font-color;

    .Main-wrapper {
      @include container;

      height: calc(100% - #{$header-height});

      .Main-logo {
        @include container;

        position: absolute;

        width: 100%;
        height: 100%;
        max-height: calc(100vh - #{$header-height});

        bottom: 0;
        z-index: 0;

        .Main-logo-lines {
          fill: rgba($color: $line-fill, $alpha: 0.5);
        }
        .Main-logo-circles {
          // fill: rgba($color: $circle-fill, $alpha: 1);
          fill: rgb(29, 69, 110); // 33%
        }
        .Main-logo-rings {
          opacity: 1;
          > g > circle {
            fill: rgba($color: $circle-fill, $alpha: 1);
            // fill: rgb(30, 86, 146); // 50%
          }
        }
      }

      .Content-wrapper {
        @include container;

        @media screen and (min-width: 720px) {
          padding: $padding;
        }

        overflow: hidden;
      }
    }
  }

  // .MuiDialog-root {
  //   div {
  //     display: flex;
  //   }
  // }

  .MuiPaper-root {
    font-family: 'Roboto', 'sans-serif';
    background: linear-gradient(0deg, #23262f 50%, #414554 200%) !important;
  }
  .MuiPopover-paper {
    background: none;
    background: linear-gradient(
      0deg,
      rgba(35, 38, 47, 0.9) 50%,
      rgba(65, 69, 84, 0.9) 200%
    ) !important;
  }
}
