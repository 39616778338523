@import 'insight/Base.scss';

.DashboardPage-wrapper {
  @include container;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 42px;
  // color: $header-color;
  // color: rgba(200, 200, 200, 0.7);
  font-weight: 400;
  text-align: center;

  .BaseCard-wrapper {
    width: unset;
    height: unset;

    padding: 100px 60px;
    margin: 0 20px;

    height: 620px;

    > div {
      @include container;

      flex-direction: column;
      justify-content: flex-start;

      >span {
        user-select: none;
        margin-top: 20px;
        filter: drop-shadow(0 0 5px red);
        font-family: inherit;
        font-size: 14px;
        line-height: 14px;
        color: red;
      }

      > pre {
        width: 400px;

        margin: 40px auto;
        margin-bottom: 0;
        font-size: 16px;
        line-height: 16px;
        font-weight: 400;

        text-align: justify;
        font-family: inherit;
        white-space: pre-line;
      }
    }
  }

  .DashboardPage-callToAction {
    @include container;

    width: 16em;
    height: 6em;

    margin: 0 auto;

    flex-grow: 0;
    flex-shrink: 0;

    > span {
      &:first-child {
        @include container;

        // flex-direction: column !important;
        justify-content: space-evenly;

        // color: white;

        > .Icon-wrapper {
          font-size: 1.5em;
          margin-right: 15px;
        }
      }
    }
  }

  // .constructionWrapper {
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  //   font-weight: 200;

  //   .imageFilter {
  //     filter: grayscale(100%);
  //     margin-top: -50px;
  //     margin-bottom: 20px;
  //   }
  // }
}
