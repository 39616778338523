@import 'insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $width: constant('resource-table-width');
  $font-size: constant('font-size-normal');
  $padding: constant('base-padding');
  $thin: constant('font-weight-1');
  // $modal-inner-wrapper-min-width: constant('modal-inner-wrapper-min-width');
  // $modal-inner-wrapper-min-height: constant('modal-inner-wrapper-min-height');
  $minWidth-name: 5em;
  $columnWidth-date: 11em;
  $columnWidth-actions: 2.25em;
  $height: 4em;

  $background-color: theme('background-2');
  $border-1: theme('border-1');
  $border-2: theme('border-2');
  $hover: theme('emphasis-1');
  $focus: theme('emphasis-1');

  .TransformationTableHeader-wrapper {
    @include container;

    align-items: center;

    width: 100%;
    height: 2em;

    flex-grow: 0;
    flex-shrink: 0;

    padding: 0 $padding;
    margin-bottom: $padding;

    cursor: default;
    user-select: none;

    .TransformationTableHeader-cell {
      @include container;

      justify-content: space-between;
      align-items: center;

      flex-shrink: 0;

      text-transform: lowercase;
      font-variant: small-caps;
      font-weight: $thin;
      letter-spacing: 0.06em;

      cursor: pointer;

      border-bottom: 1px solid rgba($color: $border-1, $alpha: 0.4);

      &:focus-within {
        border-bottom: 3px solid $focus;
      }

      > .Icon-wrapper {
        margin-right: calc(3 * #{$padding});
      }
      &.ascending {
        > .Icon-wrapper {
          transform: rotate(180deg);
        }
      }
      &.TransformationTableHeader-name {
        width: 100%;
        min-width: $minWidth-name;
        padding-left: $padding;
        flex-shrink: 1;
      }
      &.TransformationTableHeader-date {
        width: $columnWidth-date;
        flex-grow: 0;
      }
      &.TransformationTableHeader-actions {
        justify-content: flex-end;
        width: $columnWidth-actions;
        flex-grow: 0;
        cursor: unset;
      }
    }
  }
  .TransformationTableEntry-wrapper {
    @include container;
    @include focusRipple(10em);

    flex-direction: row;
    align-items: center;

    width: 100%;
    height: $height;

    flex-grow: 0;
    flex-shrink: 0;

    padding: $padding;
    margin-top: $padding;
    font-size: $font-size;

    border: 1px solid rgba($color: $border-2, $alpha: 0.4);
    background-color: $background-color;
    cursor: pointer;
    overflow: hidden;

    &:first-child {
      margin-top: 0;
    }

    &.selected {
      border-color: $hover;
    }

    .TransformationTableEntry-cell {
      @include container;

      align-items: center;

      flex-grow: 0;
      flex-shrink: 0;

      span {
        width: 100%;
        vertical-align: middle;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-variant: small-caps;
        font-size: $font-size;
        line-height: $font-size;
        font-weight: $thin;
        letter-spacing: 0.06em;
      }
      &.TransformationTableEntry-name {
        @include container;

        width: 100%;
        height: calc(#{$height} - 2 * #{$padding});
        min-width: $minWidth-name;

        flex-shrink: 1;

        padding: 0 $padding;

        span {
          text-transform: capitalize;
          font-size: 1.1em;
          line-height: 1.1em;
        }
      }
      &.TransformationTableEntry-date {
        @include container;

        width: $columnWidth-date;
        height: calc(#{$height} - 2 * #{$padding});

        flex-grow: 0;

        span {
          font-size: 0.9em;
          line-height: 0.9em;
        }
      }
      &.TransformationTableEntry-actions {
        @include container;

        justify-content: flex-end;

        width: $columnWidth-actions;
        height: calc(#{$height} - 2 * #{$padding});

        flex-grow: 0;

        user-select: none;

        .TransformationTableEntry-actionButton {
          display: flex;
          justify-content: center;
          align-items: center;

          width: 2.25em;
          height: 2.25em;

          font-size: $font-size;
          border-radius: 35%;
          border: none;
          color: currentColor;
          background: none;
          cursor: pointer;

          &:hover {
            background-color: $hover;
          }
          &:focus {
            box-shadow: inset 0px 0px 0 2px $hover;
          }
        }
      }
    }
  }
}
