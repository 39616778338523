@import 'app/insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $padding: constant('base-padding');
  $row-height: 3.5em;
  $footer-height: 3em;

  $header-footer-color: theme('background-2');
  $border-color: theme('border-1');
  $placeholder-color: theme('emphasis-1');
  $resizer-color: theme('emphasis-1');
  $checkbox-unchecked: theme('background-1');
  $checkbox-checked: theme('emphasis-1');

  .Table-wrapper {
    @include container;

    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    height: unset;
    max-height: 100%;

    flex-shrink: 1;

    user-select: none;

    .Table-content {
      @include container;

      height: unset;
      max-height: 100%;

      flex-grow: 0;
      flex-shrink: 1;

      overflow: auto;

      .Table-Column-wrapper {
        @include container;

        justify-content: flex-start;

        height: fit-content;

        flex-shrink: 1;

        overflow: hidden;

        &.checker {
          .Table-Column-content {
            .Table-Column-headerCell,
            .Table-Column-rowCell {
              cursor: unset;
            }
          }
        }
        &.placeholder {
          height: unset;

          background-color: rgba($color: $placeholder-color, $alpha: 0.3);
          .Table-Column-content {
            opacity: 0;
          }
        }
        &.filler {
          width: 0;
          .Table-Column-content {
            .Table-Column-headerCell,
            .Table-Column-rowCell {
              padding: 0;
              cursor: unset;
            }
          }
        }
        .Table-Column-content {
          @include container;

          flex-direction: column;
          justify-content: flex-start;

          flex-shrink: 1;

          overflow: hidden;

          .Table-Column-headerCell {
            @include container;

            justify-content: space-between;
            align-items: center;

            height: $row-height;

            flex-grow: 0;
            flex-shrink: 0;
            padding: $padding;

            cursor: grab;
            user-select: none;
            border: none;
            font-size: inherit;
            color: inherit;
            border-bottom: 1px solid $border-color;
            background-color: $header-footer-color;

            .Table-Column-headerCellContent {
              display: block;

              width: 100%;
              height: 100%;

              line-height: calc(#{$row-height} - 2 * #{$padding});

              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }

            .Table-Column-sortArrow {
              @include container;

              flex-direction: column;
              justify-content: center;
              align-items: center;

              width: 1em;
              height: 1em;
              flex-grow: 0;
              flex-shrink: 0;

              cursor: pointer;
            }
          }
          .Table-Column-rowCell {
            @include container;

            justify-content: flex-start;
            align-items: center;

            height: $row-height;

            flex-grow: 0;
            flex-shrink: 0;
            padding: $padding;

            overflow: hidden;
            white-space: nowrap;

            &.Table-Column-activeRow {
              cursor: pointer;
            }
          }
        }
        .Table-Column-handler {
          @include container;

          flex-direction: column;
          justify-content: center;
          align-items: center;

          width: 1em;
          height: unset;

          flex-grow: 0;
          flex-shrink: 0;
          margin-left: -1em;
          opacity: 0;
          transition: opacity 0.4s;

          cursor: w-resize;
          border-right: 0.25em solid $resizer-color;
        }
        &:hover {
          .Table-Column-handler {
            opacity: 1;
          }
        }
      }
    }
    .Table-footer {
      @include container;

      justify-content: space-between;

      height: $footer-height;
      flex-grow: 0;
      flex-shrink: 0;

      background-color: $header-footer-color;
    }
    .Table-checkboxWrapper {
      @include container;

      justify-content: center;
      align-items: center;

      width: 1em;
      height: 1em;
      flex-grow: 0;
      flex-shrink: 0;

      cursor: pointer;
      user-select: none;
      font-size: inherit;
      color: inherit;
      border: 1px solid $border-color;
      background-color: $checkbox-unchecked;

      &.checked {
        background-color: $checkbox-checked;
      }
    }
  }
}
