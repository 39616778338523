.Dev-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 100%;
  padding: 10px;
  // padding-bottom: 10em;

  // color: white;
  // background-color: #171a24;

  // > div {
  //   &:not(:first-child) {
  //     margin-top: 20px;
  //   }
  // }

  .Dev-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    height: 4em;

    margin-bottom: 20px;

    .Dev-headerButton {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      width: 10em;
      height: 3em;

      border-radius: 3px;
      border: none;
      background: none;
      font-size: inherit;
      color: currentColor;
      cursor: pointer;

      margin: 0.5em;

      &:hover {
        background-color: dodgerblue;
        font-weight: 600;
      }
    }
  }

  .Dev-test {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: calc(50% - 2em - 10px);

    flex-grow: 0;
    flex-shrink: 0;

    padding: 10px;

    // border: 1px solid white;
    // background-color: red;
  }
}
.MuiSelect-root {
  width: 180px;
  // height: 20px;
}

.MuiInputBase-root {
  width: 180px;
  .MuiInputBase-input {
    // font-size: 14px;
    // height: 28.4px;
  }
}
