@import 'insight/Base.scss';
@import 'components/ThemeProvider/themes.scss';

@include themed() {
  $width: constant('resource-table-width');
  $ultra-thin: constant('font-weight-0');
  $thin: constant('font-weight-1');
  $giant: constant('font-size-giant');
  $padding: constant('base-padding');

  .TransformationsPage-wrapper {
    @include container;
    flex-direction: row;

    .BaseCard-wrapper {
      flex-direction: column;

      // width: $width;
      width: calc(100% - 25em - #{$padding} * 3);
      height: fit-content;
      min-height: 3.75em;
      ////////////////////////
      max-height: 90%;
      ////////////////////////

      border: none;
    }

    .TransformationsPage-title {
      @include container;

      height: calc(1em + 2 * #{$padding});

      flex-grow: 0;
      padding: $padding;
      margin-top: $padding;
      font-size: $giant;
      font-weight: $ultra-thin;
      line-height: $giant;

      text-transform: uppercase;
    }

    .TransformationsPage-subTitle {
      @include container;

      height: calc(1em + 2 * #{$padding});

      flex-grow: 0;
      padding: $padding;
      margin-bottom: $padding;
      font-weight: $thin;
    }

    .TransformationsPage-horizontalScrollDiv {
      @include container;

      flex-direction: column;

      overflow: auto;

      ////////////////////////
      margin-bottom: $padding;
      ////////////////////////

      .TransformationsPage-scrollDiv {
        @include container;

        flex-direction: column;

        // width: calc(#{$width} - 2 * #{$padding});
        width: 100%;
        $minWidth-name: 5em;
        $columnWidth-date: 11em;
        $columnWidth-actions: 2.25em;
        min-width: calc(
          #{$minWidth-name + $columnWidth-date + $columnWidth-actions} + #{$padding} *
            2
        );

        overflow: auto;
      }
    }
  }
}
